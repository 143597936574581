
const config_json = {
    Auth: {
        identityPoolId: "ap-south-1:53c2b159-bf9b-41bb-b4fc-51da0ec9fb5f", //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'ap-south-1', // REQUIRED - Amazon Cognito Region
        userPoolId: "ap-south-1_3OWbMtEQo", //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '5attom1sbmqg404hrd41bqhj9j', //OPTIONAL - Amazon Cognito Web Client ID
    },
    "aws_appsync_graphqlEndpoint": "https://jm2i7v45uzcgzdex4nvioh4lhm.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
    
}

export default config_json;