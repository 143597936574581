import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

import Home from "./Components/Home/Home";
import BasicNew from "./Components/BasicNew/BasicNew";
import AdvanceNew from "./Components/AdvanceNew/AdvanceNew";
import IntermediateNew from "./Components/IntermediateNew/IntermediateNew";
import BasicLoad from "./Components/BasicLoad/BasicLoad";
import IntermediateLoad from "./Components/IntermediateLoad/IntermediateLoad";
import AdvanceLoad from "./Components/AdvanceLoad/AdvanceLoad";
import Learn from "./Components/Learn/Learn";

const signUpAttributes = ["email"];

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/basicnew">
            <BasicNew />
          </Route>
          <Route path="/basic/:uname/:pname">
            <BasicLoad />
          </Route>
          <Route path="/intermediatenew">
            <IntermediateNew />
          </Route>
          <Route path="/intermediate/:uname/:pname">
            <IntermediateLoad />
          </Route>
          <Route path="/advancenew">
            <AdvanceNew />
          </Route>
          <Route path="/advance/:uname/:pname">
            <AdvanceLoad />
          </Route>
          <Route path="/learn">
            <Learn />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withAuthenticator(App, { signUpAttributes });
