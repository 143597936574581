
export const IntermediateLessons = [
    {
      "title": [
        "Level 1",
        "10 Lectures/ 30 mins"
      ],
      "content": [
        {
          "title": "L1 - Understanding the AR space",
          "link": "https://youtu.be/BQgQK-FD3b0",
          "duration": "4.4"
        },
         {
          "title": "L2 - Adding a basic shape in AR",
          "link": "https://youtu.be/LiUq-7TWtfY",
          "duration": "2.35"
        },
         {
          "title": " L3 - Positioning objects in AR",
          "link": "https://youtu.be/jMFRKVl-cIM",
          "duration": "2.42"
        },
         {
          "title": " L4 - Understanding different views in AR",
          "link": "https://youtu.be/qnltu1yL39A",
          "duration": "2.22"
        },
         {
          "title": "L5 - Scaling objects in AR",
          "link": "https://youtu.be/GwrTQF5Rt8Q",
          "duration": "2.08"
        }, {
          "title": "L6 – Making the objects visible and invisible",
          "link": "https://youtu.be/qnltu1yL39A",
          "duration": "2.13"
        }, {
          "title": " L7 – Transforming multiple shapes in AR",
          "link": "https://youtu.be/vksuqmgjzzI",
          "duration": "3.43"
        },
         {
          "title": "L8 – Understanding lighting of the AR space",
          "link": "https://youtu.be/UfjHTx8_5IQ",
          "duration": "5.01"
        },
         {
          "title": " L9 – Understanding the components",
          "link": "https://youtu.be/F2OrYtFNPtg",
          "duration": "2.55"
        },
         {
          "title": "L10 – Building our app in AR",
          "link": "https://youtu.be/pZn8AbcV3zY",
          "duration": "1.57"
        }
      ]
    },
  
  {
      "title": [
        "Level 2",
        "7 Lectures/ 16 mins"
      ],
      "content": [
        {
          "title": "L1 – How to create Github login",
          "link": "https://youtu.be/R1oCta-KY54",
          "duration": "1.15"
        },
        {
          "title": "L2 – How to create Github repository",
          "link": "https://youtu.be/AhI55KMGKrg",
          "duration": "3.24"
        },
        {
          "title": "L3 – How to upload 3D models in repository",
          "link": "https://youtu.be/Qmrdr2W2DVE",
          "duration": "2:53"
        },
        {
          "title": "L4 – How to import a 3D model",
          "link": "https://youtu.be/zuDfFqrgx-E",
          "duration": "2.21"
        },
        {
          "title": "L5 – How to align a 3D model",
          "link": "https://youtu.be/_GePI6Vu92U",
          "duration": "3.28"
        },
        {
          "title": "L6 – How to animate your 3D model",
          "link": "https://youtu.be/K76c82fz-PQ",
          "duration": "2.29"
        },
        {
          "title": "L7 – How to publish your app",
          "link": "https://youtu.be/K4VFvI45Xwk",
          "duration": "1.4"
        }
      ]
    },
  
  {
      "title": [
        "Level 3",
        "4 Lectures/ 12.64 mins"
      ],
      "content": [
        {
          "title": "L1 – Importing 3D models for the game in AR",
          "link": "https://youtu.be/ffq9i2Nx8vk",
          "duration": "2.38"
        },
        {
          "title": "L2 – Aligning the 3D models for the game",
          "link": "https://youtu.be/pP6ZBGt5938",
          "duration": "3.51"
        },
         {
          "title": "L3 – Block Coding the game logic",
          "link": "https://youtu.be/vfgXoxD-U9U",
          "duration": "6.18"
        },
         {
          "title": "L4 – Testing the game on PC",
          "link": "https://youtu.be/TLQAXsaBjHc",
          "duration": "0.57"
        }
      ]
    },
  
  {
      "title": [
        "Level 4",
        "3 Lectures/ 7 mins"
      ],
      "content": [
        {
          "title": "L1 – Understanding the VR space",
          "link": "https://youtu.be/0ZqgnZYRSFM",
          "duration": "3.07"
        },
        {
          "title": "L2 – Adding a basic shape in VR",
          "link": "https://youtu.be/rUVYAz_3nbY",
          "duration": "1.4"
        },
        {
          "title": "L3 – Testing the app on PC in VR space",
          "link": "https://youtu.be/Tp8useg-5sg",
          "duration": "1.41"
        }
      ]
    },
  
  {
      "title": [
        "Level 5",
        "5 Lectures/ 12 mins"
      ],
      "content": [
        {
          "title": "L1 – How to set up an environment",
          "link": "https://youtu.be/SmUCtE-lKHM",
          "duration": "1.56"
        },
        {
          "title": " L2 – Importing the 3D model into the environment",
          "link": "https://youtu.be/aPBqbym7lzg",
          "duration": "2.08"
        },
         {
          "title": "L3 – Block coding and aligning the 3D model",
          "link": "https://youtu.be/PFntSGvpSr8",
          "duration": "2.14"
        },
         {
          "title": "L4 – Animating the 3D model",
          "link": "https://youtu.be/OGt4-1mcakM",
          "duration": "3.09"
        },
         {
          "title": "L5 – Building the application",
          "link": "https://youtu.be/h5C2ktMuwJo",
          "duration": "1.22"
        }
      ]
    },
  
  {
      "title": [
        "Level 6",
        "6 Lectures/ 24 mins"
      ],
      "content": [
        {
          "title": "L1 – Setting up the game environment",
          "link": "https://youtu.be/oC91ClTeWVs",
          "duration": "1.18"
        },
        {
          "title": "L2 – Importing a 3D models for the game",
          "link": "https://youtu.be/mkybTWMNPp8",
          "duration": "2.45"
        },
        {
          "title": "L3 – Aligning the 3D models for the game",
          "link": "https://youtu.be/QtgW6Ul8uhM",
          "duration": "5.37"
        },
        {
          "title": "L4 – Block Coding the game logic",
          "link": "https://youtu.be/BlLaz9Kh7RU",
          "duration": "8.45"
        },
        {
          "title": "L5 - Building the app",
          "link": "https://youtu.be/7D4P5GK6woE",
          "duration": "1.06"
        },
        {
          "title": "L6 – Testing the game on PC",
          "link": "https://youtu.be/Q9dGgqD2DHo",
          "duration": "2.34"
        }
      ]
    },
     {
      "title": [
        "Level 7",
        "3 Lectures/ 13 mins"
      ],
      "content": [
        {
          "title": "L1 – Moving a character",
          "link": "https://youtu.be/tbEaPPkT8Y8",
          "duration": "2.22"
        },
        {
          "title": "L2 - Implementing a bowling game",
          "link": "https://youtu.be/W61-91HexlU",
          "duration": "4.08"
        },
           {
          "title": "L3 -Applying Physics collision",
          "link": "https://youtu.be/keX0KPkOjk4",
          "duration": "5.46"
        }
      ]
    },
  ];


export const ILesson1 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 1</b> </div>
                            <div style={{float:"right",color:"grey"}}>10 lectures/ 30 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=BQgQK-FD3b0" style={{color:"black"}} > L1 - Understanding the AR space </a></div>
                            <div style={{float:"right",color:"grey"}}>4:40 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=LiUq-7TWtfY" style={{color:"black"}} > L2 - Adding a basic shape in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:35 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=jMFRKVl-cIM" style={{color:"black"}} > L3 - Positioning objects in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:42 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=qnltu1yL39A" style={{color:"black"}} > L4 - Understanding different views in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:22 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=GwrTQF5Rt8Q" style={{color:"black"}} > L5 - Scaling objects in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:08 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=zc0oeoCVov4" style={{color:"black"}} > L6 - Making the objects visible and invisible </a></div>
                            <div style={{float:"right",color:"grey"}}>2:13 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=vksuqmgjzzI" style={{color:"black"}} > L7 - Transforming multiple shapes in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>3:43 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=UfjHTx8_5IQ" style={{color:"black"}} > L8 - Understanding lighting of the AR space </a></div>
                            <div style={{float:"right",color:"grey"}}>5:01 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=F2OrYtFNPtg" style={{color:"black"}} > L9 - Understanding the components </a></div>
                            <div style={{float:"right",color:"grey"}}>2:55 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=pZn8AbcV3zY" style={{color:"black"}} > L10 - Building our app in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>1:57 mins</div> 
                        </li>                                                       
                    </ul>
                </div>
);


export const ILesson2 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 2</b> </div>
                            <div style={{float:"right",color:"grey"}}>7 lectures/ 18 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=R1oCta-KY54" style={{color:"black"}} > L1 - How to create Github login </a></div>
                            <div style={{float:"right",color:"grey"}}>1:15 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=AhI55KMGKrg" style={{color:"black"}} > L2 - How to create Github repository </a></div>
                            <div style={{float:"right",color:"grey"}}>3:24 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Qmrdr2W2DVE" style={{color:"black"}} > L3 - How to upload 3D models in repository </a></div>
                            <div style={{float:"right",color:"grey"}}>2:53 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=zuDfFqrgx-E" style={{color:"black"}} > L4 - How to import a 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>2:21 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=_GePI6Vu92U" style={{color:"black"}} > L5 - How to align a 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>3:28 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=K76c82fz-PQ" style={{color:"black"}} > L6 - How to animate your 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>2:29 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=K4VFvI45Xwk" style={{color:"black"}} > L7 - How to publish your app </a></div>
                            <div style={{float:"right",color:"grey"}}>1:14 mins</div> 
                        </li>                                                                       
                    </ul>
                </div>
);

export const ILesson3 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 3</b> </div>
                            <div style={{float:"right",color:"grey"}}>4 lectures/ 15 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ffq9i2Nx8vk" style={{color:"black"}} > L1- Importing 3D models for the game in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:38 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=pP6ZBGt5938" style={{color:"black"}} > L2 - Aligning the 3D models for the game </a></div>
                            <div style={{float:"right",color:"grey"}}>3:51 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=vfgXoxD-U9U" style={{color:"black"}} > L3 - Block Coding the game logic </a></div>
                            <div style={{float:"right",color:"grey"}}>6:18 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=TLQAXsaBjHc" style={{color:"black"}} > L4 - Testing the game on PC </a></div>
                            <div style={{float:"right",color:"grey"}}>0:57 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ILesson4 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 4</b> </div>
                            <div style={{float:"right",color:"grey"}}>3 lectures/ 7 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=0ZqgnZYRSFM" style={{color:"black"}} > L1 - Understanding the VR space </a></div>
                            <div style={{float:"right",color:"grey"}}>3:07 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=rUVYAz_3nbY" style={{color:"black"}} > L2 - Adding a basic shape in VR </a></div>
                            <div style={{float:"right",color:"grey"}}>1:40 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Tp8useg-5sg" style={{color:"black"}} > L3 - Testing the app on PC in VR space </a></div>
                            <div style={{float:"right",color:"grey"}}>1:41 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ILesson5 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 5</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 12 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=SmUCtE-lKHM" style={{color:"black"}} > L1 - How to set up an environment </a></div>
                            <div style={{float:"right",color:"grey"}}>1:56 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=aPBqbym7lzg" style={{color:"black"}} > L2 - Importing the 3D model into the environment </a></div>
                            <div style={{float:"right",color:"grey"}}>2:08 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=PFntSGvpSr8" style={{color:"black"}} > L3 - Block coding and aligning the 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>2:14 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=OGt4-1mcakM" style={{color:"black"}} > L4 - Animating the 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>3:09 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=h5C2ktMuwJo" style={{color:"black"}} > L5 - Building the application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:22 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ILesson6 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 6</b> </div>
                            <div style={{float:"right",color:"grey"}}>6 lectures/ 24 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=oC91ClTeWVs" style={{color:"black"}} > L1 - Setting up the game environment </a></div>
                            <div style={{float:"right",color:"grey"}}>1:18 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=mkybTWMNPp8" style={{color:"black"}} > L2 - Importing a 3D models for the game </a></div>
                            <div style={{float:"right",color:"grey"}}>2:45 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=QtgW6Ul8uhM" style={{color:"black"}} > L3 - Aligning the 3D models for the game </a></div>
                            <div style={{float:"right",color:"grey"}}>5:37 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=BlLaz9Kh7RU" style={{color:"black"}} > L4 - Block Coding the game logic </a></div>
                            <div style={{float:"right",color:"grey"}}>8:45 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=7D4P5GK6woE" style={{color:"black"}} > L5 - Building the app </a></div>
                            <div style={{float:"right",color:"grey"}}>1:06 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Q9dGgqD2DHo" style={{color:"black"}} > L6 - Testing the game on PC </a></div>
                            <div style={{float:"right",color:"grey"}}>2:34 mins</div> 
                        </li> 
                    </ul>
                </div>
);

export const ILesson7 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 7</b> </div>
                            <div style={{float:"right",color:"grey"}}>3 lectures/ 13 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=tbEaPPkT8Y8" style={{color:"black"}} > L1 - Moving a character </a></div>
                            <div style={{float:"right",color:"grey"}}>2:22 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=W61-91HexlU" style={{color:"black"}} > L2 - Implementing a bowling game </a></div>
                            <div style={{float:"right",color:"grey"}}>4:08 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=keX0KPkOjk4" style={{color:"black"}} > L3 - Applying Physics collision </a></div>
                            <div style={{float:"right",color:"grey"}}>5:46 mins</div> 
                        </li>
                    </ul>
                </div>
);

