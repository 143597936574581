

export const LessonArrayJaon = [
    {
        "title" : [
            "Level 1", "11 Lectures/ 43 mins"
        ],
        "content" : [
            {
                "title" : "L0 - How to create an account",
                "link" : "https://www.youtube.com/watch?v=84S2n3HRkqM",
                "duration" : "2:52"
            },
            {
                "title" : "L1 - Setting up the environment",
                "link" : "https://youtu.be/SgS3BPU2v5A",
                "duration" : "1:58"
            },
            {
                "title" : "L2 - Adding an Object",
                "link" : "https://youtu.be/RYC1dZ6HMHU",
                "duration" : "3:04"
            },
            {
                "title" : "L3 - How to position the object",
                "link" : "https://youtu.be/enRmMivPaqI",
                "duration" : "5:12"
            },
            {
                "title" : "L4 - Adding Multiple Objects",
                "link" : "https://youtu.be/MZqTaZ4Ihg4",
                "duration" : "4:16"
            },
            {
                "title" : "L5 - Scaling the Objects",
                "link" : "https://youtu.be/IEURP6RRbCE",
                "duration" : "5:14"
            },
            {
                "title" : "L6 - Rotating the Objects",
                "link" : "https://youtu.be/QQGM8iP_3fA",
                "duration" : "2:38"
            },
            {
                "title" : "L7 - Colouring the Objects",
                "link" : "https://youtu.be/FWElcyM8i8Y",
                "duration" : "3:23"
            },
            {
                "title" : "L8 - Destroying the Objects",
                "link" : "https://youtu.be/zRxbi0Y4mq4",
                "duration" : "2:09"
            },
            {
                "title" : "L9 - Enabling and Disabling the blocks",
                "link" : "https://youtu.be/j9CC4niBAic",
                "duration" : "4:03"
            },
            {
                "title" : "L10 - Performing some basic operations on objects",
                "link" : "https://youtu.be/2kyu0_tRFJU",
                "duration" : "7:56"
            }
        ]
    },
    {
        "title" : [
            "Level 2", "9 Lectures/ 30 mins"
        ],
        "content" : [
            {
                "title" : "L1 - Downloading and converting a 3D model",
                "link" : "https://youtu.be/GxxqO3xay7Y",
                "duration" : "5:03"
            },
            {
                "title" : "L2 - Importing the 3D model",
                "link" : "https://youtu.be/cbPlwqTqHKI",
                "duration" : "2:19"
            },
            {
                "title" : "L3 - Navigation using keyboard movement block",
                "link" : "https://youtu.be/Hqar8r0TnGw",
                "duration" : "2:20"
            },
            {
                "title" : "L4 - Moving the objects using keys on the keyboard",
                "link" : "https://youtu.be/MHqcub7pcP4",
                "duration" : "6:12"
            },
            {
                "title" : "L5 - Moving the object using mouse",
                "link" : "https://youtu.be/JAJnr4sEHHY",
                "duration" : "3:20"
            },
            {
                "title" : "L6 - Using the keyboard as a input device",
                "link" : "https://youtu.be/TwxGfuHpv68",
                "duration" : "3:37"
            },
            {
                "title" : "L7 - Using the mouse as a input device",
                "link" : "https://youtu.be/n7soygTh_Y0",
                "duration" : "2:11"
            },
            {
                "title" : "L8 - Moving the object using Bluetooth controller",
                "link" : "https://youtu.be/alwu1s1kaSs",
                "duration" : "1:29"
            },
            {
                "title" : "L9 - Finding the objects using movement control block",
                "link" : "https://youtu.be/0QRpU-7dNlg",
                "duration" : "3:30"
            }
        ]
    },
    {
        "title" : [
            "Level 3", "6 Lectures/ 25 mins"
        ],
        "content" : [
            {
                "title" : "L1 - Understanding the Scaling animation",
                "link" : "https://youtu.be/IqXHvV6TsOU",
                "duration" : "4:52"
            },
            {
                "title" : "L2 - Understanding the Rotation animation",
                "link" : "https://youtu.be/xwClBaBd3es",
                "duration" : "3:53"
            },
            {
                "title" : "L3 - Learning more about position animation",
                "link" : "https://youtu.be/dnMrmZr80bs",
                "duration" : "5:13"
            },
            {
                "title" : "L4 - Applying various animations on multiple objects",
                "link" : "https://youtu.be/qq15FMFpHWw",
                "duration" : "2:58"
            },
            {
                "title" : "L5 - Understanding Duration of an animation",
                "link" : "https://youtu.be/kEWuaLUGstw",
                "duration" : "3:20"
            },
            {
                "title" : "L6 - Looping the animations",
                "link" : "https://youtu.be/fArIiSSvGIQ",
                "duration" : "3:08"
            }
        ]
    },
    {
        "title" : [
            "Level 4", "4 Lectures/ 21 mins"
        ],
        "content" : [
            {
                "title" : "L1 - Adding text to a 3D object",
                "link" : "https://youtu.be/dp9Ugt70hOg",
                "duration" : "5:17"
            },
            {
                "title" : "L2 - Decrementing and incrementing the text",
                "link" : "https://youtu.be/Y-wGlk-v8S0",
                "duration" : "4:45"
            },
            {
                "title" : "L3 - Changing the lighting of the objects",
                "link" : "https://youtu.be/jKIBwU3zCw0",
                "duration" : "3:32"
            },
            {
                "title" : "L4 - Changing the parameters of the text block",
                "link" : "https://youtu.be/eeFLe2xRkok",
                "duration" : "2:28"
            }
        ]
    },
    {
        "title" : [
            "Level 5", "4 Lectures/ 17 mins"
        ],
        "content" : [
            {
                "title" : "L1 - Understanding functions and delay blocks",
                "link" : "https://youtu.be/1hFQ-6TbBPA",
                "duration" : "5:24"
            },
            {
                "title" : "L2 - To use functions and delay blocks on multiple objects",
                "link" : "https://youtu.be/ggefBffI-X0",
                "duration" : "2:43"
            },
            {
                "title" : "L3 - Introduction to variables",
                "link" : "https://youtu.be/BNMtrdvZJrA",
                "duration" : "6:26"
            },
            {
                "title" : " L4 - Introduction to nested if loops ",
                "link" : "https://youtu.be/p3pI42lV-wY",
                "duration" : "4:16"
            }
        ]
    },
    {
        "title" : [
            "Level 6", "2 Lectures/ 9 mins"
        ],
        "content" : [
            {
                "title" : " L1 - Creating a shooting game using monkey heads",
                "link" : "https://youtu.be/NAkurJatjeY",
                "duration" : "3:59"
            },
            {
                "title" : "L2 - Creating a shooting game using heads using monkey heads and drones",
                "link" : "https://youtu.be/ZYdrDETJEpY",
                "duration" : "4:07"
            }
        ]
    },
    {
        "title" : [
            "Level 7", "3 Lectures/ 6 mins"
        ],
        "content" : [
            {
                "title" : " L1 - Understanding VR space ",
                "link" : "https://youtu.be/v4fVOq79XP8",
                "duration" : "1:35"
            },
            {
                "title" : "L2 - Adding a basic shape (Cube) in virtual reality",
                "link" : "https://youtu.be/-QNg9s8XA90",
                "duration" : "1:34"
            },
            {
                "title" : "L3 - Testing the app on PC in VR space",
                "link" : "https://youtu.be/wkpfO2KHlFM",
                "duration" : "2:00"
            }
        ]
    },
    {
        "title" : [
            "Level 8", "6 Lectures/ 12 mins"
        ],
        "content" : [
            {
                "title" : "L1 -  Setting up the environment in VR",
                "link" : "https://youtu.be/SAKTE7KZmt0",
                "duration" : "1:17"
            },
            {
                "title" : "L2 - Importing the 3D model to the environment in VR",
                "link" : "https://youtu.be/zD1aMBqnXiM",
                "duration" : "2:35"
            },
            {
                "title" : "L3 - Block coding and aligning the 3D modeling in VR",
                "link" : "https://youtu.be/N_qyTZ7FxL8",
                "duration" : "2:44"
            },
            {
                "title" : "L4 - Animating the 3D model in VR",
                "link" : "https://youtu.be/nkz5bW5pejQ",
                "duration" : "2:11"
            },
            {
                "title" : "L5 - Building the application in VR",
                "link" : "https://youtu.be/WMPHI4Q_b8Y",
                "duration" : "0:56"
            },
            {
                "title" : "L6 - Installing and testing the application",
                "link" : "https://youtu.be/SI2xYFwo-CU",
                "duration" : "1:09"
            }
        ]
    },
    {
        "title" : [
            "Level 9", "7 Lectures/ 22 mins"
        ],
        "content" : [
            {
                "title" : "L1 -  Setting up the game environment",
                "link" : "https://youtu.be/tLG2NhoTwqA",
                "duration" : "1:16"
            },
            {
                "title" : "L2 - Importing 3D Models",
                "link" : "https://youtu.be/njPwd2pobyQ",
                "duration" : "3:21"
            },
            {
                "title" : "L3 - Aligning the models",
                "link" : "https://youtu.be/RoodFF5Awac",
                "duration" : "6:43"
            },
            {
                "title" : "L4 - Block Coding the Game logic",
                "link" : "https://youtu.be/PuAuUQV-Itg",
                "duration" : "6:12"
            },
            {
                "title" : "L5 - Testing out the Game functionality on PC",
                "link" : "https://youtu.be/tdWX0TtqHek",
                "duration" : "1:45"
            },
            {
                "title" : "L6 - Building the application",
                "link" : "https://youtu.be/dk1Ht91aow0",
                "duration" : "0:57"
            },
            {
                "title" : "L7 - Installing and testing the application",
                "link" : "https://youtu.be/OoTmpex4nUA",
                "duration" : "1:24"
            }
        ]
    }
]



export const Lesson10 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 10</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 8 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=lhoKhTtem2g" style={{color:"black"}} > L1 - Understanding AR space </a></div>
                            <div style={{float:"right",color:"grey"}}>2:21 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=K4tP1sdl3y8" style={{color:"black"}} > L2 - Adding a basic shape in augmented reality </a></div>
                            <div style={{float:"right",color:"grey"}}>1:17 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=tDGyaJUlr60" style={{color:"black"}} > L3 - Building the AR application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:22 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=kSq9qPDVhYQ" style={{color:"black"}} > L4 - Getting started with the AR application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:13 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=zigjSuZtJxo" style={{color:"black"}} > L5 - Viewing our Model in AR space </a></div>
                            <div style={{float:"right",color:"grey"}}>1.10 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson11 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 11</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 10 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=iKt5CHnZBcA" style={{color:"black"}} > L1 - Importing the 3D model into AR space </a></div>
                            <div style={{float:"right",color:"grey"}}>1:54 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=eWViY2nXz30" style={{color:"black"}} > L2 - Block coding and aligning the 3D model in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:16 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=BJV5YwJA9-s" style={{color:"black"}} > L3 – Animating the 3D model in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>3:08 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=PdIkkPGiTMqY" style={{color:"black"}} > L4 – Building the application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:05 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=tVwlGZ8KpE4" style={{color:"black"}} > L5 - Installing and testing the application </a></div>
                            <div style={{float:"right",color:"grey"}}>0:57 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson12 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 12</b> </div>
                            <div style={{float:"right",color:"grey"}}>6 lectures/ 12 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=A7EHotH-t6M" style={{color:"black"}} > L1 - Importing 3D models in AR </a></div>
                            <div style={{float:"right",color:"grey"}}>1:50 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=qshs9RFUssk" style={{color:"black"}} > L2 - Aligning the 3D models </a></div>
                            <div style={{float:"right",color:"grey"}}>2:40 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=S5mxczr7iYA" style={{color:"black"}} > L3 - Block Coding the game logic </a></div>
                            <div style={{float:"right",color:"grey"}}>2:41 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=xv_hl-R6Wmo" style={{color:"black"}} > L4 - Testing the game on PC </a></div>
                            <div style={{float:"right",color:"grey"}}>1:36 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ZUrQGP-ppAs" style={{color:"black"}} > L5 - Building the application </a></div>
                            <div style={{float:"right",color:"grey"}}>0:58 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=8Mk5M8y5glI" style={{color:"black"}} > L6 - Installing and testing the application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:11 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson13 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Github Basics</b> </div>
                            <div style={{float:"right",color:"grey"}}>4 lectures/ 12 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ctx-Qop1bTU" style={{color:"black"}} > L1 - Creating Github account </a></div>
                            <div style={{float:"right",color:"grey"}}>1:16 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=6QDHxtn2KGY" style={{color:"black"}} > L2 - Creating a repository </a></div>
                            <div style={{float:"right",color:"grey"}}>3:24 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=PYpLz8iUWZY" style={{color:"black"}} > L3 - Uploading 3D model in a repository </a></div>
                            <div style={{float:"right",color:"grey"}}>2:53 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=hip289mT1Iw" style={{color:"black"}} > L4 - Importing 3D model in a repository </a></div>
                            <div style={{float:"right",color:"grey"}}>2:22 mins</div> 
                        </li>
                    </ul>
                </div>
);


export const Lesson14 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Colliders</b> </div>
                            <div style={{float:"right",color:"grey"}}>10 lectures/ 40 mins</div>
                        </li>
                        {/* <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=xlDViEyEPD8" style={{color:"black"}} > Installing the enablar code software </a></div>
                            <div style={{float:"right",color:"grey"}}>2:12 mins</div> 
                        </li>  */}
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=iP0xiNqfNIc" style={{color:"black"}} > L1 - Learning to Bounce objects </a></div>
                            <div style={{float:"right",color:"grey"}}>4:40 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=6I9u9rYrM3E" style={{color:"black"}} > L2 - Change properties on collision </a></div>
                            <div style={{float:"right",color:"grey"}}>5:08 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=EVKdwgQANwA" style={{color:"black"}} > L3 - Applying high and low Friction </a></div>
                            <div style={{float:"right",color:"grey"}}>5:26 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=aOjlOaLvUrg" style={{color:"black"}} > L4 - Implementing Zero Gravity </a></div>
                            <div style={{float:"right",color:"grey"}}>3:39 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=qAOgT7EU9Pk" style={{color:"black"}} > L5 - Applying gravity across x, y and z axes </a></div>
                            <div style={{float:"right",color:"grey"}}>3:47 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=9gQsQTVluRs" style={{color:"black"}} > L6 - Moving a character </a></div>
                            <div style={{float:"right",color:"grey"}}>1:36 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=-gDDvg5PptE" style={{color:"black"}} > L7 - Flying an airplane </a></div>
                            <div style={{float:"right",color:"grey"}}>2:58 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=RQnWpzkpH5E" style={{color:"black"}} > L8 - Implementing bullets in a shooting game </a></div>
                            <div style={{float:"right",color:"grey"}}>4:53 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=-088GvR8G2w" style={{color:"black"}} > L9 - Bowling game </a></div>
                            <div style={{float:"right",color:"grey"}}>3:54 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=zrn80GH9eJA" style={{color:"black"}} > L10 - Obtaining field values using things speak </a></div>
                            <div style={{float:"right",color:"grey"}}>4:23 mins</div> 
                        </li>                                                       
                    </ul>
                </div>
);


export const Lesson1 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 1</b> </div>
                            <div style={{float:"right",color:"grey"}}>11 lectures/ 43 mins</div>
                        </li>
                        {/* <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=xlDViEyEPD8" style={{color:"black"}} > Installing the enablar code software </a></div>
                            <div style={{float:"right",color:"grey"}}>2:12 mins</div> 
                        </li>  */}
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=84S2n3HRkqM" style={{color:"black"}} > L0 - How to create an account </a></div>
                            <div style={{float:"right",color:"grey"}}>2:52 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=SgS3BPU2v5A" style={{color:"black"}} > L1 - Setting up the environment </a></div>
                            <div style={{float:"right",color:"grey"}}>1:58 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=RYC1dZ6HMHU" style={{color:"black"}} > L2 - Adding an Object </a></div>
                            <div style={{float:"right",color:"grey"}}>3:04 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=enRmMivPaqI" style={{color:"black"}} > L3 - How to position the object </a></div>
                            <div style={{float:"right",color:"grey"}}>5:12 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=MZqTaZ4Ihg4" style={{color:"black"}} > L4 - Adding Multiple Objects </a></div>
                            <div style={{float:"right",color:"grey"}}>4:16 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=IEURP6RRbCE" style={{color:"black"}} > L5 - Scaling the Objects </a></div>
                            <div style={{float:"right",color:"grey"}}>5:14 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=QQGM8iP_3fA" style={{color:"black"}} > L6 - Rotating the Objects </a></div>
                            <div style={{float:"right",color:"grey"}}>2:38 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=FWElcyM8i8Y" style={{color:"black"}} > L7 - Colouring the Objects </a></div>
                            <div style={{float:"right",color:"grey"}}>3:23 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=zRxbi0Y4mq4" style={{color:"black"}} > L8 - Destroying the Objects </a></div>
                            <div style={{float:"right",color:"grey"}}>2:09 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=j9CC4niBAic" style={{color:"black"}} > L9 - Enabling and Disabling the blocks </a></div>
                            <div style={{float:"right",color:"grey"}}>4:03 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=2kyu0_tRFJU" style={{color:"black"}} > L10 - Performing some basic operations on objects </a></div>
                            <div style={{float:"right",color:"grey"}}>7:56 mins</div> 
                        </li>                                                       
                    </ul>
                </div>
);


export const Lesson2 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 2</b> </div>
                            <div style={{float:"right",color:"grey"}}>9 lectures/ 30 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=GxxqO3xay7Y" style={{color:"black"}} > L1 - Downloading and converting a 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>5:03 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=cbPlwqTqHKI" style={{color:"black"}} > L2 - Importing the 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>2:19 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Hqar8r0TnGw" style={{color:"black"}} > L3 - Navigation using keyboard movement block </a></div>
                            <div style={{float:"right",color:"grey"}}>2:20 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=MHqcub7pcP4" style={{color:"black"}} > L4 - Moving the objects using keys on the keyboard </a></div>
                            <div style={{float:"right",color:"grey"}}>6:12 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=JAJnr4sEHHY" style={{color:"black"}} > L5 - Moving the object using mouse </a></div>
                            <div style={{float:"right",color:"grey"}}>3:20 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=TwxGfuHpv68" style={{color:"black"}} > L6 - Using the keyboard as a input device </a></div>
                            <div style={{float:"right",color:"grey"}}>3:37 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=n7soygTh_Y0" style={{color:"black"}} > L7 - Using the mouse as a input device </a></div>
                            <div style={{float:"right",color:"grey"}}>2:11 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=alwu1s1kaSs" style={{color:"black"}} > L8 - Moving the object using Bluetooth controller </a></div>
                            <div style={{float:"right",color:"grey"}}>1:29 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=0QRpU-7dNlg" style={{color:"black"}} > L9 - Finding the objects using movement control block </a></div>
                            <div style={{float:"right",color:"grey"}}>3:30 mins</div> 
                        </li>                                                                         
                    </ul>
                </div>
);

export const Lesson3 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 3</b> </div>
                            <div style={{float:"right",color:"grey"}}>6 lectures/ 25 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=IqXHvV6TsOU" style={{color:"black"}} > L1- Understanding the Scaling animation </a></div>
                            <div style={{float:"right",color:"grey"}}>4:52 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=xwClBaBd3es" style={{color:"black"}} > L2 - Understanding the Rotation animation </a></div>
                            <div style={{float:"right",color:"grey"}}>3:53 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=dnMrmZr80bs" style={{color:"black"}} > L3 - Learning more about position animation </a></div>
                            <div style={{float:"right",color:"grey"}}>5:13 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=qq15FMFpHWw" style={{color:"black"}} > L4 - Applying various animations on multiple objects </a></div>
                            <div style={{float:"right",color:"grey"}}>2:58 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=kEWuaLUGstw" style={{color:"black"}} > L5 - Understanding Duration of an animation </a></div>
                            <div style={{float:"right",color:"grey"}}>3:20 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=fArIiSSvGIQ" style={{color:"black"}} > L6 - Looping the animations </a></div>
                            <div style={{float:"right",color:"grey"}}>3:08 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson4 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 4</b> </div>
                            <div style={{float:"right",color:"grey"}}>4 lectures/ 17 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=dp9Ugt70hOg" style={{color:"black"}} > L1 - Adding text to a 3D object </a></div>
                            <div style={{float:"right",color:"grey"}}>5:17 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Y-wGlk-v8S0" style={{color:"black"}} > L2 - Decrementing and incrementing the text </a></div>
                            <div style={{float:"right",color:"grey"}}>4:45 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=jKIBwU3zCw0" style={{color:"black"}} > L3 - Changing the lighting of the objects </a></div>
                            <div style={{float:"right",color:"grey"}}>3:32 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=eeFLe2xRkok" style={{color:"black"}} > L4 - Changing the parameters of the text block </a></div>
                            <div style={{float:"right",color:"grey"}}>2:28 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson5 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 5</b> </div>
                            <div style={{float:"right",color:"grey"}}>4 lectures/ 21 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=1hFQ-6TbBPA" style={{color:"black"}} > L1 - Understanding functions and delay blocks </a></div>
                            <div style={{float:"right",color:"grey"}}>5:24 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ggefBffI-X0" style={{color:"black"}} > L2 - To use functions and delay blocks on multiple objects </a></div>
                            <div style={{float:"right",color:"grey"}}>2:43 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=BNMtrdvZJrA" style={{color:"black"}} > L3 - Introduction to variables </a></div>
                            <div style={{float:"right",color:"grey"}}>6:26 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=p3pI42lV-wY" style={{color:"black"}} > L4 - Introduction to nested if loops </a></div>
                            <div style={{float:"right",color:"grey"}}>4:16 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson6 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 6</b> </div>
                            <div style={{float:"right",color:"grey"}}>2 lectures/ 9 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=NAkurJatjeY" style={{color:"black"}} > L1 - Creating a shooting game using monkey heads </a></div>
                            <div style={{float:"right",color:"grey"}}>3:59 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ZYdrDETJEpY" style={{color:"black"}} > L2 - Creating a shooting game using heads using monkey heads and drones </a></div>
                            <div style={{float:"right",color:"grey"}}>4:07 mins</div> 
                        </li> 
                    </ul>
                </div>
);

export const Lesson7 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 7</b> </div>
                            <div style={{float:"right",color:"grey"}}>3 lectures/ 6 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=v4fVOq79XP8" style={{color:"black"}} > L1 - Understanding VR space </a></div>
                            <div style={{float:"right",color:"grey"}}>1:35 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=-QNg9s8XA90" style={{color:"black"}} > L2 - Adding a basic shape (Cube) in virtual reality </a></div>
                            <div style={{float:"right",color:"grey"}}>1:34 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=wkpfO2KHlFM" style={{color:"black"}} > L3 - Testing the app on PC in VR space </a></div>
                            <div style={{float:"right",color:"grey"}}>2:00 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson8 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 8</b> </div>
                            <div style={{float:"right",color:"grey"}}>6 lectures/ 12 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=SAKTE7KZmt0" style={{color:"black"}} > L1 -  Setting up the environment in VR </a></div>
                            <div style={{float:"right",color:"grey"}}>1:17 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=zD1aMBqnXiM" style={{color:"black"}} > L2 - Importing the 3D model to the environment in VR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:35 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=N_qyTZ7FxL8" style={{color:"black"}} > L3 - Block coding and aligning the 3D modeling in VR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:44 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=nkz5bW5pejQ" style={{color:"black"}} > L4 - Animating the 3D model in VR </a></div>
                            <div style={{float:"right",color:"grey"}}>2:11 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=WMPHI4Q_b8Y" style={{color:"black"}} > L5 - Building the application in VR </a></div>
                            <div style={{float:"right",color:"grey"}}>0:56 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=SI2xYFwo-CU" style={{color:"black"}} > L6 - Installing and testing the application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:09 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const Lesson9 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 9</b> </div>
                            <div style={{float:"right",color:"grey"}}>7 lectures/ 22 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=tLG2NhoTwqA" style={{color:"black"}} > L1 -  Setting up the game environment </a></div>
                            <div style={{float:"right",color:"grey"}}>1:16 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=njPwd2pobyQ" style={{color:"black"}} > L2 - Importing 3D Models </a></div>
                            <div style={{float:"right",color:"grey"}}>3:21 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=RoodFF5Awac" style={{color:"black"}} > L3 - Aligning the models </a></div>
                            <div style={{float:"right",color:"grey"}}>6:43 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=PuAuUQV-Itg" style={{color:"black"}} > L4 - Block Coding the Game logic </a></div>
                            <div style={{float:"right",color:"grey"}}>6:15 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=tdWX0TtqHek" style={{color:"black"}} > L5 - Testing out the Game functionality on PC </a></div>
                            <div style={{float:"right",color:"grey"}}>1:45 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=dk1Ht91aow0" style={{color:"black"}} > L6 - Building the application </a></div>
                            <div style={{float:"right",color:"grey"}}>0:57 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=OoTmpex4nUA" style={{color:"black"}} > L7 - Installing and testing the application </a></div>
                            <div style={{float:"right",color:"grey"}}>1:24 mins</div> 
                        </li>
                    </ul>
                </div>
);