import shadows from "@material-ui/core/styles/shadows";
import React, { Component, useEffect, useState } from "react";

import "./DesignToolBar.css";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const DesignToolBar = (props) => {
  let mode = props.type;
  const [menuone, setMenuOne] = useState(false);
  const [menutwo, setMenuTwo] = useState("");
  const [selectedObject, setSelectedObject] = useState("");
  const menuOneList = [
    "Basic Object",
    "3D model",
    "Environment",
    "Texture",
    "360 Image",
    "Delete",
  ];
  const basicObjectList = [
    "Box",
    "Circle",
    "Cone",
    "Cylinder",
    "Dodecahedron",
    "Tetrahedron",
    "Torus",
    "Triangle",
  ];
  const environmentList = [
    "Forest",
    "Egypt",
    "Contact",
    "Yavapai",
    "Arches",
    "Dream",
  ];

  const addBasicObject = () => {
    var objectid = document.getElementById("basic_object_id").value;

    if (objectid.replaceAll(" ", "") === "") {
      console.log("empty");
    } else {
      if (basicObjectList.includes(selectedObject)) {
        var parent = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("Main Parent");
        var aentity = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.createElement(`a-${selectedObject.toLowerCase()}`);
        aentity.setAttribute("id", `${objectid}`);
        aentity.setAttribute("position", "0 0 0");
        aentity.setAttribute("rotation", "0 0 0");
        aentity.setAttribute("scale", "1 1 1");

        parent.appendChild(aentity);
        document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("Main Parent")
          .flushToDOM(true);
        document
          .getElementById(`${mode}_iframe_aframe`)
          .contentWindow.AFRAME.INSPECTOR.initUI();
      } else {
        alert("Please select one shapre");
      }
    }
  };

  const add3DModel = () => {
    let modelid = document.getElementById("model_3d_id").value;
    let modellink = document.getElementById("model_3d_link").value;
    let cameracheck = document.getElementById("model_3d_camera").checked;

    if (modelid.replaceAll(" ", "") === "") {
      alert("Please enter ID");
    } else if (modellink.replaceAll(" ", "") === "") {
      alert("Please enter Link");
    } else {
      if (cameracheck) {
        var parent = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("Camera-entities");
      } else {
        var parent = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("Main Parent");
      }
      var model = document
        .getElementById(`${mode}_iframe_aframe`)
        .contentDocument.createElement("a-gltf-model");
      model.setAttribute("id", modelid);
      model.setAttribute("src", modellink);
      parent.appendChild(model);
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentWindow.AFRAME.INSPECTOR.initUI();
      alert("Model Added");
    }
  };

  const selectEnvironment = () => {
    if (environmentList.includes(selectedObject)) {
      try {
        var env = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("environment");
        env.remove();
        var parent = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("Default-scene");
        var aentity = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.createElement("a-entity");
        aentity.setAttribute("id", "environment");
        aentity.setAttribute(
          "environment",
          `preset: ${selectedObject.toLowerCase()}`
        );
        // localStorage.setItem('env', selectedObject.toLowerCase());
        parent.appendChild(aentity);
        document
          .getElementById(`${mode}_iframe_aframe`)
          .contentWindow.AFRAME.INSPECTOR.initUI();
      } catch {
        var parent = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.getElementById("Default-scene");
        var aentity = document
          .getElementById(`${mode}_iframe_aframe`)
          .contentDocument.createElement("a-entity");
        aentity.setAttribute("id", "environment");
        aentity.setAttribute(
          "environment",
          `preset: ${selectedObject.toLowerCase()}`
        );
        // localStorage.setItem('env', selectedObject.toLowerCase());
        parent.appendChild(aentity);
        document
          .getElementById(`${mode}_iframe_aframe`)
          .contentWindow.AFRAME.INSPECTOR.initUI();
      }
    } else {
      alert("Please select environment");
    }
  };

  const addTexture = () => {
    let textureobject = document.getElementById("texture_object_id").value;
    let texturelink = document.getElementById("texture_link").value;

    if (textureobject.replaceAll(" ", "") === "") {
      alert("Please enter  object ID");
    } else if (texturelink.replaceAll(" ", "") === "") {
      alert("Please enter Link");
    } else {
      var objectname = document
        .getElementById(`${mode}_iframe_aframe`)
        .contentDocument.getElementById(textureobject);
      objectname.setAttribute("material", "src: url(" + texturelink + ")");
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentDocument.getElementById("Main Parent")
        .flushToDOM(true);
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentWindow.AFRAME.INSPECTOR.initUI();
      alert("Model Added");
    }
  };

  const add360Image = () => {
    let imagelink = document.getElementById("image_360_link").value;
    if (imagelink.replaceAll(" ", "") === "") {
      alert("Please Link");
    } else {
      var objectname = document
        .getElementById(`${mode}_iframe_aframe`)
        .contentDocument.getElementById("Default-sky");
      objectname.setAttribute("radius", "10");
      objectname.setAttribute("material", "src: url(" + imagelink + ")");
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentDocument.getElementById("Main Parent")
        .flushToDOM(true);
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentWindow.AFRAME.INSPECTOR.initUI();
      alert("Model Added");
    }
  };

  const deleteObject = () => {
    let objectid = document.getElementById("delete_id").value;
    try {
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentDocument.getElementById(objectid)
        .remove();
      document
        .getElementById(`${mode}_iframe_aframe`)
        .contentWindow.AFRAME.INSPECTOR.initUI();
      alert("Object Deleted");
    } catch (e) {
      alert("Error: " + e);
    }
  };

  return (
    <>
      {menuone ? (
        <>
          <div
            id="add_button"
            onClick={() => {
              setMenuOne(!menuone);
            }}
          >
            <span>Close</span>
          </div>
          <div id="object_menu" className="noselect">
            {menuOneList.map((item) => (
              <span
                key={item}
                className="menuOneItem"
                onClick={() => {
                  setMenuTwo(item);
                }}
              >
                {item}
              </span>
            ))}
          </div>
          <p id="seperator_line"></p>

          {menutwo === "Basic Object" ? (
            <>
              <div className="basic_object_menu">
                <h2>Basic Object</h2>
                <input
                  type="text"
                  id="basic_object_id"
                  className="basic_object"
                  placeholder="Enter ID here"
                />
                {basicObjectList.map((item) => (
                  <span
                    key={item}
                    className="basic_object_items noselect"
                    style={{
                      color: selectedObject == item ? "#3F51B5" : "#202122",
                    }}
                    onClick={() => {
                      setSelectedObject(item);
                    }}
                  >
                    {item}
                  </span>
                ))}
                <Button
                  color="inherit"
                  variant="contained"
                  //color="primary"
                  onClick={addBasicObject}
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}

          {menutwo === "3D model" ? (
            <>
              <div className="basic_object_menu">
                <h2>3D Model</h2>
                <input
                  type="text"
                  id="model_3d_id"
                  className="basic_object"
                  placeholder="Enter ID here"
                />
                <input
                  type="text"
                  id="model_3d_link"
                  className="basic_object"
                  placeholder="Enter Link here"
                />
                <div id="model_camera">
                  <span>Add to Camera? &nbsp;&nbsp;</span>
                  <input type="checkbox" id="model_3d_camera" />
                </div>
                <Button
                  color="inherit"
                  variant="contained"
                  //color="primary"
                  onClick={add3DModel}
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}

          {menutwo === "Environment" ? (
            <>
              <div className="environment_object_menu">
                <h2
                  style={{
                    width: "150px !important",
                    paddingRight: "10px",
                    paddingLeft: "80px",
                    marginBottom: "35px",
                  }}
                >
                  Environment
                </h2>
                {environmentList.map((item) => (
                  <span
                    key={item}
                    className="basic_object_items noselect"
                    style={{
                      color: selectedObject == item ? "#3F51B5" : "#202122",
                    }}
                    onClick={() => {
                      setSelectedObject(item);
                    }}
                  >
                    {item}
                  </span>
                ))}
                <Button
                  onClick={selectEnvironment}
                  color="inherit"
                  variant="contained"
                  //color="primary"
                  style={{ marginTop: "15px", marginLeft: "130px" }}
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}

          {menutwo === "Texture" ? (
            <>
              <div className="basic_object_menu">
                <h2>Texture</h2>
                <input
                  type="text"
                  id="texture_object_id"
                  className="basic_object"
                  placeholder="Enter Object ID here"
                />
                <input
                  type="text"
                  id="texture_link"
                  className="basic_object"
                  placeholder="Enter Link here"
                />
                <Button
                  color="inherit"
                  variant="contained"
                  //color="primary"
                  onClick={addTexture}
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}

          {menutwo === "360 Image" ? (
            <>
              <div className="basic_object_menu">
                <h2 style={{ marginBottom: "40px" }}>360 Image</h2>
                <input
                  type="text"
                  id="image_360_link"
                  className="basic_object"
                  placeholder="Enter Link here"
                />
                <Button
                  color="inherit"
                  variant="contained"
                  //color="primary"
                  onClick={add360Image}
                >
                  Add
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}

          {menutwo === "Delete" ? (
            <>
              <div className="basic_object_menu">
                <h2 style={{ marginBottom: "40px" }}>Delete Object</h2>
                <input
                  type="text"
                  id="delete_id"
                  className="basic_object"
                  placeholder="Enter Object ID here"
                />
                <Button
                  color="inherit"
                  variant="contained"
                  //color="primary"
                  onClick={deleteObject}
                >
                  Delete
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div
            id="add_button"
            onClick={() => {
              setMenuOne(!menuone);
              setMenuTwo("Basic Object");
            }}
          >
            <span>Add + </span>
          </div>
        </>
      )}
    </>
  );
};

export default DesignToolBar;
