/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEnablarProject = /* GraphQL */ `
  mutation CreateEnablarProject($input: CreateEnablarProjectInput!) {
    createEnablarProject(input: $input) {
      difficulty
      ef1
      html
      id
      runcode
      tk
      xml
    }
  }
`;

export const deleteEnablarProject = /* GraphQL */ `
  mutation DeleteEnablarProject($input: DeleteEnablarProjectInput!) {
    deleteEnablarProject(input: $input) {
      difficulty
      ef1
      html
      id
      runcode
      tk
      xml
    }
  }
`;

export const updateEnablarProject = /* GraphQL */ `
  mutation UpdateEnablarProject($input: UpdateEnablarProjectInput!) {
    updateEnablarProject(input: $input) {
      difficulty
      ef1
      html
      id
      runcode
      tk
      xml
    }
  }
`;
