import React, { Component, useEffect, useState } from 'react';
import { listEnablarProjects, getEnablarProject } from '../../graphql/queries';
import { createEnablarProject, updateEnablarProject } from '../../graphql/mutations';


import { API } from 'aws-amplify';

export const SaveCodeBasic = props => {
    let return_code = false;
    if(localStorage.getItem(props['username']) === "free"){
        alert("Projects can not be saved in free account");
    }
    else{
        saveBasic(props["username"], props["projectname"]).then(data => {
            return_code = data;
        });
        console.log("reutn code " + return_code);
    }
    return return_code;
}

export const SaveCodeIntermediate = props => {
    let return_code = false; 
    if(localStorage.getItem(props['username']) === "free"){
        alert("Projects can not be saved in free account");
    }
    else{
        saveIntermediate(props["username"], props["projectname"]).then(data => {
            return_code = data;
        });
        console.log("reutn code " + return_code);
    }
    return return_code;
}

export const SaveCodeAdvance = props => {
    let return_code = false;
    if(localStorage.getItem(props['username']) === "free"){
        alert("Projects can not be saved in free account");
    }
    else{
        saveAdvance(props["username"], props["projectname"]).then(data => {
            return_code = data;
        });
        console.log("reutn code " + return_code);
    }
    return return_code;
}

async function saveBasic(username, projectname) {
    let rc = false;
    if (document.getElementById("project-name").value.replace(/\s+/g, "") === "") {
        alert("Please enter project name");
        rc = false;
    }
    else if (document.getElementById("project-name").value.includes("-")) {
        alert("Please remove - from project name");
        rc = false;
    }
    else {
        let iframe = document.getElementById("iframe_block");
        let xmlcode = iframe.contentWindow.savecode();
        let code = iframe.contentWindow.runcode();
        try {
            let tempd = await API.graphql({
                query: createEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        difficulty: "basic",
                        tk: username,
                        xml: xmlcode,
                        runcode: code
                    }
                }
            });
        }
        catch {
            let tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        difficulty: "basic",
                        tk: username,
                        xml: xmlcode,
                        runcode: code
                    }
                }
            });
        }
        rc = true;
    }
    return rc;
}


async function saveIntermediate(username, projectname) {
    let rc = false;
    if (document.getElementById("project-name").value.replace(/\s+/g, "") === "") {
        alert("Please enter project name");
        rc = false;
    }
    else if (document.getElementById("project-name").value.includes("-")) {
        alert("Please remove - from project name");
        rc = false;
    }
    else {
        let iframe = document.getElementById("intermediate_iframe_block");
        let xmlcode = iframe.contentWindow.savecode();
        let code = iframe.contentWindow.runcode();
        let str1 = "";
        let str2 = "";

        document.getElementById("intermediate_iframe_aframe").contentDocument.getElementById("Main Parent").flushToDOM(true);

        str1 = document.getElementById("intermediate_iframe_aframe").contentDocument.getElementById("Main Parent").innerHTML;
        

        try {
            if (document.getElementById("intermediate_iframe_aframe").contentDocument.getElementById("environment")) {
                document.getElementById("intermediate_iframe_aframe").contentDocument.getElementById("environment").flushToDOM();
                str2 = `/////${document.getElementById("intermediate_iframe_aframe").contentDocument.getElementById("environment").getAttribute("environment").preset}`;
            }
            else {
                str2 = `///// `;
            }
        }
        catch {
            console.log("error");
        }

        let htmldata = str1 + str2;

        try {
            let tempd = await API.graphql({
                query: createEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        difficulty: "intermediate",
                        tk: username,
                        html: htmldata
                    }
                }
            });
            tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        xml: xmlcode,
                        runcode: code
                    }
                }
            });
        }
        catch {
            let tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        tk: username,
                        xml: xmlcode,
                        runcode: code
                    }
                }
            });
            tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        difficulty: "intermediate",
                        html:htmldata
                    }
                }
            });
        }
        rc = true;
    }
    return rc;
}

async function saveAdvance(username, projectname) {
    let rc = false;
    if (document.getElementById("project-name").value.replace(/\s+/g, "") === "") {
        alert("Please enter project name");
        rc = false;
    }
    else if (document.getElementById("project-name").value.includes("-")) {
        alert("Please remove - from project name");
        rc = false;
    }
    else {
        let iframe = document.getElementById("advance_iframe_block");
        let xmlcode = iframe.contentWindow.savecode();
        let code = iframe.contentWindow.runcode();
        let str1 = "";
        let str2 = "";

        document.getElementById("advance_iframe_aframe").contentDocument.getElementById("Main Parent").flushToDOM(true);

        str1 = document.getElementById("advance_iframe_aframe").contentDocument.getElementById("Main Parent").innerHTML;
        

        try {
            if (document.getElementById("advance_iframe_aframe").contentDocument.getElementById("environment")) {
                document.getElementById("advance_iframe_aframe").contentDocument.getElementById("environment").flushToDOM();
                str2 = `/////${document.getElementById("advance_iframe_aframe").contentDocument.getElementById("environment").getAttribute("environment").preset}`;
            }
            else {
                str2 = `///// `;
            }
        }
        catch {
            console.log("error");
        }

        let htmldata = str1 + str2;

        try {
            let tempd = await API.graphql({
                query: createEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        difficulty: "advance",
                        tk: username,
                        html: htmldata
                    }
                }
            });
            tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        xml: xmlcode,
                        runcode: code
                    }
                }
            });
        }
        catch {
            let tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        tk: username,
                        xml: xmlcode,
                        runcode: code
                    }
                }
            });
            tempd = await API.graphql({
                query: updateEnablarProject, variables: {
                    input: {
                        id: username + "-" + projectname,
                        difficulty: "advance",
                        html:htmldata
                    }
                }
            });
        }
        rc = true;
    }
    return rc;
}
  