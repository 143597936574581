import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import config_json from './exports';
import Amplify, { Auth } from 'aws-amplify';


Amplify.configure(config_json);

const currentConfig = Auth.configure();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
