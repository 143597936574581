

export async function saveShortURL(reqType,project){

    return new Promise(function (resolve, reject){
        var data = JSON.stringify({
            "type": reqType,
            "project": project
        });
        
        var xhr = new XMLHttpRequest();
        
        
        // xhr.addEventListener("readystatechange", function() {
        //     if(this.readyState === 4) {
        //     console.log(this.responseText);
        //     }
        // });

        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };

        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.open("POST", "https://b4s57kb2wcvxz63vhaflw4cs7m0mxdsp.lambda-url.ap-south-1.on.aws/");
        xhr.send(data);
    });
}