import React, { useState } from "react";
import "./Learn.css";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import { LessonArrayJaon,  Lesson1,Lesson2,Lesson3,Lesson4,Lesson5,Lesson6,Lesson7,Lesson8,Lesson9,Lesson10,Lesson11,Lesson12,Lesson13,Lesson14} from "./Lessons";
import { IntermediateLessons,ILesson1, ILesson2, ILesson3, ILesson4, ILesson5, ILesson6, ILesson7,} from "./IntermediateLessons";
import { AdvanceLessons, ALesson1, ALesson2, ALesson3, ALesson4, ALesson5, ALesson6, ALesson7, ALesson8, } from "./AdvanceLessons";

import Button from "@material-ui/core/Button";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

const Learn = () => {
  const [state, setState] = useState("Lesson1");
  const [mode, setModes] = useState("basic");
  const [lesson,setLesson] = useState(0);

  document.title = "Learn Section";
  return (
    <div
      style={{
        background: "url('assets/images/LearnSection_bg.png')",
        backgroundSize: "cover",
        maxWidth: "100vw",
        height: "170vh",
      }}
    >
      <div className="my-flex">
        <h1 style={{ color: "white" }}>LEARN SECTION</h1>
      </div>

      <div className="my-flex">
        <Button
          className="mybutton1"
          color="primary"
          variant="contained"
          onClick={() => {
            setModes("basic");
            setState("Lesson1");
          }}
        >
          basic
        </Button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <Button
          className="mybutton2"
          variant="contained"
          onClick={() => {
            setModes("intermediate");
            setState("Lesson1");
          }}
        >
          intermediate
        </Button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <Button
          className="mybutton3"
          variant="contained"
          onClick={() => {
            setModes("advance");
            setState("Lesson1");
          }}
        >
          advance
        </Button>
      </div>


      {mode === "basic" ?
        <div className=" my-flex flex1">
          <div className="flex1-left">
            <h2>Basic</h2>
            <h4 className="level-title" onClick={() => {setLesson(0);}} ><VideoLibraryIcon />&nbsp;Level 1</h4>
            <h4 className="level-title" onClick={() => {setLesson(1);}} ><VideoLibraryIcon />&nbsp;Level 2</h4>
            <h4 className="level-title" onClick={() => {setLesson(2);}} ><VideoLibraryIcon />&nbsp;Level 3</h4>
            <h4 className="level-title" onClick={() => {setLesson(3);}} ><VideoLibraryIcon />&nbsp;Level 4</h4>
            <h4 className="level-title" onClick={() => {setLesson(4);}} ><VideoLibraryIcon />&nbsp;Level 5</h4>
            <h4 className="level-title" onClick={() => {setLesson(5);}} ><VideoLibraryIcon />&nbsp;Level 6</h4>
            <h4 className="level-title" onClick={() => {setLesson(6);}} ><VideoLibraryIcon />&nbsp;Level 7</h4>
            <h4 className="level-title" onClick={() => {setLesson(7);}} ><VideoLibraryIcon />&nbsp;Level 8</h4>
            <h4 className="level-title" onClick={() => {setLesson(8);}} ><VideoLibraryIcon />&nbsp;Level 9</h4>
          </div>
          <div className="flex1-right">
            <div className="lesson-content">
              <h2>{LessonArrayJaon[lesson]["title"][0]}</h2>
              <h2>{LessonArrayJaon[lesson]["title"][1]}</h2>
            </div>
            <div>
              {LessonArrayJaon[lesson]["content"].map((data) => (
                <div data-fancybox="gallery" data-src={data['link']} className="lesson-content border-top" >
                  <h4><PlayCircleOutlineIcon style={{position:"relative", top:"5px"}}/> &nbsp;{data["title"]}</h4>
                  <h4>{data["duration"]} mins</h4>
                </div>
              ))}
            </div>
          </div>
        </div>

        :
        <></>
      }

    {mode === "intermediate" ?
        <div className=" my-flex flex1">
          <div className="flex1-left">
            <h2>Intermediate</h2>
            <h4 className="level-title" onClick={() => {setLesson(0);}} ><VideoLibraryIcon />&nbsp;Level 1</h4>
            <h4 className="level-title" onClick={() => {setLesson(1);}} ><VideoLibraryIcon />&nbsp;Level 2</h4>
            <h4 className="level-title" onClick={() => {setLesson(2);}} ><VideoLibraryIcon />&nbsp;Level 3</h4>
            <h4 className="level-title" onClick={() => {setLesson(3);}} ><VideoLibraryIcon />&nbsp;Level 4</h4>
            <h4 className="level-title" onClick={() => {setLesson(4);}} ><VideoLibraryIcon />&nbsp;Level 5</h4>
            <h4 className="level-title" onClick={() => {setLesson(5);}} ><VideoLibraryIcon />&nbsp;Level 6</h4>
            <h4 className="level-title" onClick={() => {setLesson(6);}} ><VideoLibraryIcon />&nbsp;Level 7</h4>
          </div>
          <div className="flex1-right">
            <div className="lesson-content">
              <h2>{IntermediateLessons[lesson]["title"][0]}</h2>
              <h2>{IntermediateLessons[lesson]["title"][1]}</h2>
            </div>
            <div>
              {IntermediateLessons[lesson]["content"].map((data) => (
                <div data-fancybox="gallery" data-src={data['link']} className="lesson-content border-top" >
                  <h4><PlayCircleOutlineIcon style={{position:"relative", top:"5px"}} /> &nbsp;{data["title"]}</h4>
                  <h4>{data["duration"]} mins</h4>
                </div>
              ))}
            </div>
          </div>
        </div>

        :
        <></>
      }

    {mode === "advance" ?
        <div className=" my-flex flex1">
          <div className="flex1-left">
            <h2>Advance</h2>
            <h4 className="level-title" onClick={() => {setLesson(0);}} ><VideoLibraryIcon />&nbsp;Level 1</h4>
            <h4 className="level-title" onClick={() => {setLesson(1);}} ><VideoLibraryIcon />&nbsp;Level 2</h4>
            <h4 className="level-title" onClick={() => {setLesson(2);}} ><VideoLibraryIcon />&nbsp;Level 3</h4>
            <h4 className="level-title" onClick={() => {setLesson(3);}} ><VideoLibraryIcon />&nbsp;Level 4</h4>
            <h4 className="level-title" onClick={() => {setLesson(4);}} ><VideoLibraryIcon />&nbsp;Level 5</h4>
            <h4 className="level-title" onClick={() => {setLesson(5);}} ><VideoLibraryIcon />&nbsp;Level 6</h4>
            <h4 className="level-title" onClick={() => {setLesson(6);}} ><VideoLibraryIcon />&nbsp;Level 7</h4>
            <h4 className="level-title" onClick={() => {setLesson(7);}} ><VideoLibraryIcon />&nbsp;Level 8</h4>
          </div>
          <div className="flex1-right">
            <div className="lesson-content">
              <h2>{AdvanceLessons[lesson]["title"][0]}</h2>
              <h2>{AdvanceLessons[lesson]["title"][1]}</h2>
            </div>
            <div>
              {AdvanceLessons[lesson]["content"].map((data) => (
                <div data-fancybox="gallery" data-src={data['link']} className="lesson-content border-top" >
                  <h4><PlayCircleOutlineIcon style={{position:"relative", top:"5px"}} /> &nbsp;{data["title"]}</h4>
                  <h4>{data["duration"]} mins</h4>
                </div>
              ))}
            </div>
          </div>
        </div>

        :
        <></>
      }

      {/* <div class="container-fluid pt-3">
                <div class="row justify-content-md-center"> 
                    <div class="col-lg-2 col-sm-2 col-xs-12">
                        <div style={{backgroundColor:"rgba(11,174,254,0.7)",height:mode === "basic"?"8vh":"6vh",width:"12vw",borderRadius:"15px 15px",border:"0.25px solid white"}} ><a class="nav-link" href="javascript:void(0);" style={{color:"white",fontSize:"2.5vh"}} onClick={() => {setModes("basic");setState("Lesson1")}}>Basic</a></div>
                    </div>
                    <div class="col-lg-2 col-sm-2 col-xs-12">
                        <div style={{backgroundColor:"rgba(32,226,14)",height:mode === "intermediate"?"8vh":"6vh",width:"12vw",borderRadius:"15px 15px",border:"0.25px solid white"}} ><a class="nav-link" href="javascript:void(0);" style={{color:"white",fontSize:"2.5vh"}} onClick={() => {setModes("intermediate");setState("Lesson1")}}>Intermediate</a></div>
                    </div> 
                    <div class="col-lg-2 col-sm-2 col-xs-12">
                        <div style={{backgroundColor:"rgba(255,185,40)",height:mode === "advance"?"8vh":"6vh",width:"12vw",borderRadius:"15px 15px",border:"0.25px solid white"}} ><a class="nav-link" href="javascript:void(0);" style={{color:"white",fontSize:"2.5vh"}} onClick={() => {setModes("advance");setState("Lesson1")}}>Advance</a></div>
                    </div>
                </div>
            </div> */}
{/* 
      <div class="container-fluid pt-3">
        {mode === "basic" ? (
          <div class="row justify-content-md-center">
            <div class="col-lg-2 col-sm-2 col-xs-12">
              <ul
                class="list-group list-group-flush"
                style={{ borderRadius: "15px", textAlign: "center" }}
              >
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    fontSize: "120%",
                  }}
                >
                  <b>Learning Levels</b>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson1");
                    }}
                  >
                    {" "}
                    Level 1{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson2");
                    }}
                  >
                    {" "}
                    Level 2{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson3");
                    }}
                  >
                    {" "}
                    Level 3{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson4");
                    }}
                  >
                    {" "}
                    Level 4{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson5");
                    }}
                  >
                    {" "}
                    Level 5{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson6");
                    }}
                  >
                    {" "}
                    Level 6{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson7");
                    }}
                  >
                    {" "}
                    Level 7{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson8");
                    }}
                  >
                    {" "}
                    Level 8{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson9");
                    }}
                  >
                    {" "}
                    Level 9{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson10");
                    }}
                  >
                    {" "}
                    Level 10{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson11");
                    }}
                  >
                    {" "}
                    Level 11{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson12");
                    }}
                  >
                    {" "}
                    Level 12{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson13");
                    }}
                  >
                    {" "}
                    Github Basics{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson14");
                    }}
                  >
                    {" "}
                    Colliders{" "}
                  </a>
                </li>
              </ul>
            </div>
            {state === "Lesson1" ? <Lesson1 /> : ""}
            {state === "Lesson2" ? <Lesson2 /> : ""}
            {state === "Lesson3" ? <Lesson3 /> : ""}
            {state === "Lesson4" ? <Lesson4 /> : ""}
            {state === "Lesson5" ? <Lesson5 /> : ""}
            {state === "Lesson6" ? <Lesson6 /> : ""}
            {state === "Lesson7" ? <Lesson7 /> : ""}
            {state === "Lesson8" ? <Lesson8 /> : ""}
            {state === "Lesson9" ? <Lesson9 /> : ""}
            {state === "Lesson10" ? <Lesson10 /> : ""}
            {state === "Lesson11" ? <Lesson11 /> : ""}
            {state === "Lesson12" ? <Lesson12 /> : ""}
            {state === "Lesson13" ? <Lesson13 /> : ""}
            {state === "Lesson14" ? <Lesson14 /> : ""}
          </div>
        ) : (
          <></>
        )}
        {mode === "intermediate" ? (
          <div class="row justify-content-md-center">
            <div class="col-lg-2 col-sm-2 col-xs-12">
              <ul
                class="list-group list-group-flush"
                style={{ borderRadius: "15px", textAlign: "center" }}
              >
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    fontSize: "120%",
                  }}
                >
                  <b>Learning Levels</b>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson1");
                    }}
                  >
                    {" "}
                    Level 1{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson2");
                    }}
                  >
                    {" "}
                    Level 2{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson3");
                    }}
                  >
                    {" "}
                    Level 3{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson4");
                    }}
                  >
                    {" "}
                    Level 4{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson5");
                    }}
                  >
                    {" "}
                    Level 5{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson6");
                    }}
                  >
                    {" "}
                    Level 6{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson7");
                    }}
                  >
                    {" "}
                    Level 7{" "}
                  </a>
                </li>
              </ul>
            </div>
            {state === "Lesson1" ? <ILesson1 /> : ""}
            {state === "Lesson2" ? <ILesson2 /> : ""}
            {state === "Lesson3" ? <ILesson3 /> : ""}
            {state === "Lesson4" ? <ILesson4 /> : ""}
            {state === "Lesson5" ? <ILesson5 /> : ""}
            {state === "Lesson6" ? <ILesson6 /> : ""}
            {state === "Lesson7" ? <ILesson7 /> : ""}
          </div>
        ) : (
          <></>
        )}
        {mode === "advance" ? (
          <div class="row justify-content-md-center">
            <div class="col-lg-2 col-sm-2 col-xs-12">
              <ul
                class="list-group list-group-flush"
                style={{ borderRadius: "15px", textAlign: "center" }}
              >
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    fontSize: "120%",
                  }}
                >
                  <b>Learning Levels</b>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson1");
                    }}
                  >
                    {" "}
                    Level 1{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson2");
                    }}
                  >
                    {" "}
                    Level 2{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson3");
                    }}
                  >
                    {" "}
                    Level 3{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson4");
                    }}
                  >
                    {" "}
                    Level 4{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson5");
                    }}
                  >
                    {" "}
                    Level 5{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson6");
                    }}
                  >
                    {" "}
                    Level 6{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson7");
                    }}
                  >
                    {" "}
                    Level 7{" "}
                  </a>
                </li>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    borderTop: "1.5px solid black",
                  }}
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0);"
                    style={{ color: "black" }}
                    onClick={() => {
                      setState("Lesson8");
                    }}
                  >
                    {" "}
                    Level 8{" "}
                  </a>
                </li>
              </ul>
            </div>
            {state === "Lesson1" ? <ALesson1 /> : ""}
            {state === "Lesson2" ? <ALesson2 /> : ""}
            {state === "Lesson3" ? <ALesson3 /> : ""}
            {state === "Lesson4" ? <ALesson4 /> : ""}
            {state === "Lesson5" ? <ALesson5 /> : ""}
            {state === "Lesson6" ? <ALesson6 /> : ""}
            {state === "Lesson7" ? <ALesson7 /> : ""}
            {state === "Lesson8" ? <ALesson8 /> : ""}
          </div>
        ) : (
          <></>
        )}
      </div>*/}
    </div> 
  );

  // return(mainData);
};

export default Learn;
