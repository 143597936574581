import React, { Component, useEffect, useState } from "react";
import {
  listEnablarProjects,
  getEnablARCodeUsers,
} from "../../graphql/queries";
import { deleteEnablarProject } from "../../graphql/mutations";
import "./Home.css";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";

import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Home = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  const [basicprojects, setBasicProjects] = useState([]);
  const [intermediateprojects, setIntermediateProjects] = useState([]);
  const [advanceprojects, setAdvanceProjects] = useState([]);
  const [projectToDelete, setProjectToDelete] = useState("");
  const [license, setLicense] = useState("loading");
  const [isLoading, setIsLoading] = useState(true);
  const [expiryDate, setExpiryDate] = useState("");
  const [licenseLoading, setLicenseLoading] = useState(false);
  // const [basicprojects, setBasicProjects] = useState(["project1 asdasd", "project2", "project3"]);
  // const [intermediateprojects, setIntermediateProjects] = useState(["project1", "project2", "project3"]);
  // const [advanceprojects, setAdvanceProjects] = useState(["project1", "project2", "project3"]);

  const [basicogprojects, setBasicOgProjects] = useState([]);
  const [intermediateogprojects, setIntermediateOgProjects] = useState([]);
  const [advanceogprojects, setAdvanceOgProjects] = useState([]);

  const handleQRmodalClose = () => {
    document.getElementById("delete-bg").style.display = "none";
  };

  // const [basicogprojects, setBasicOgProjects] = useState(["project1 asdasd", "project2", "project3"]);
  // const [intermediateogprojects, setIntermediateOgProjects] = useState(["project1", "project2", "project3"]);
  // const [advanceogprojects, setAdvanceOgProjects] = useState(["project1", "project2", "project3"]);

  useEffect(() => {
    getLicense();
    getProjects();
  }, []);

  async function getLicense() {
    localStorage.setItem(
      Auth.user.username + "//" + Auth.user.attributes.email,
      "free"
    );
    let tempd = await API.graphql({
      query: getEnablARCodeUsers,
      variables: {
        User: Auth.user.username + "//" + Auth.user.attributes.email,
      },
    });
    // console.log("user deatails " + tempd.data.getEnablARObotzUsers);
    if (tempd.data.getEnablARCodeUsers !== null) {
      if (Date.now() <= Date.parse(tempd.data.getEnablARCodeUsers.expiry)) {
        setLicense("premium");
        setExpiryDate(tempd.data.getEnablARCodeUsers.expiry.substr(0, 15));
        console.log("premium");
        localStorage.setItem(Auth.user.username, "premium");
      } else {
        setLicense("free");
        console.log("fere");
        localStorage.setItem(Auth.user.username, "free");
      }
    } else {
      setLicense("free");
      localStorage.setItem(Auth.user.username, "free");

      console.log("free");
    }
  }
  async function getProjects() {
    let nexttoken = "";
    let basicArray = [];
    let intermediateArray = [];
    let advanceArray = [];
    while (nexttoken !== null) {
      let tempd = await API.graphql({
        query: listEnablarProjects,
        variables: {
          filter: {
            tk: { eq: Auth.user.username },
          },
          limit: 1000,
          nextToken: nexttoken,
        },
      });
      nexttoken = tempd.data.listEnablarProjects.nextToken;
      tempd.data.listEnablarProjects.items.forEach((item) => {
        if (item["difficulty"] === "basic") {
          basicArray.push(item["id"].split("-")[1]);
        } else if (item["difficulty"] === "intermediate") {
          intermediateArray.push(item["id"].split("-")[1]);
        } else if (item["difficulty"] === "advance") {
          advanceArray.push(item["id"].split("-")[1]);
        }
      });
    }
    setBasicProjects(basicArray);
    setIntermediateProjects(intermediateArray);
    setAdvanceProjects(advanceArray);
    setBasicOgProjects(basicArray);
    setIntermediateOgProjects(intermediateArray);
    setAdvanceOgProjects(advanceArray);
    setIsLoading(false);
    // console.log("basic list " + basicArray);
    // console.log("inter list " + intermediateArray);
    // console.log("advance list " + advanceArray);
  }

  const handleLicenseClose = () => {
    document.getElementById("license-bg").style.display = "none";
  };

  const showLicense = (e) => {
    document.getElementById("license-bg").style.display = "block";
  };

  const submitLicnse = (e) => {
    setLicenseLoading(true);
    var couponid = document.getElementById("license_input").value;
    var username = Auth.user.username;
    var userdata = Auth.user.attributes.email;
    // var jsondata  = JSON.parse( userdata);
    var data = `${username}//${userdata}`;
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
        if (this.responseText === '"Coupon successfully redeemed"') {
          console.log("in if");
          handleLicenseClose();
          getLicense();
          // alert("coupon successfully redeemed. Please close this window and refresh the webpage.");

          // handleLicenseClose();
          // getLicense();
          if (couponid.startsWith("ddec")) {
            var username = Auth.user.username;
            // var data = JSON.stringify({
            //     "id": username
            // });
            var data = username;

            var xhr = new XMLHttpRequest();

            xhr.addEventListener("readystatechange", function () {
              if (this.readyState === 4) {
                // console.log(this.responseText);
                // alert(this.responseText);

                window.location.reload();
              }
            });

            xhr.open(
              "POST",
              "https://t5bpasok4f.execute-api.ap-south-1.amazonaws.com/dev/addprojects"
            );
            // xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader(
              "Content-Type",
              "application/x-www-form-urlencoded"
            );

            xhr.send(data);
          } else {
            window.location.reload();
          }
        } else {
          console.log("in else " + this.responseText);
          alert(this.responseText);
        }

        setLicenseLoading(false);
      }
    });
    xhr.open(
      "POST",
      `https://t5bpasok4f.execute-api.ap-south-1.amazonaws.com/dev/coupon?coupon=${couponid}&type=redeem`
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(data);

    // handleLicenseClose();
    //             getLicense();
  };

  const basicProjectDelete = (e) => {
    console.log("clicked " + e);
    // deleteProject(e);
    setProjectToDelete(e);
    document.getElementById("delete-bg").style.display = "block";
  };

  async function deleteProject() {
    let projectname = projectToDelete;
    try {
      let tempd = await API.graphql({
        query: deleteEnablarProject,
        variables: {
          input: {
            id: Auth.user.username + "-" + projectname,
          },
        },
      });
    } catch {
      alert("error deleting project!!");
    }
    getProjects();
    // window.location.reload();
    handleQRmodalClose();
  }

  const basicProjectLoad = (e) => {
    console.log("clicked " + e);
    history.push(encodeURI("/basic/" + Auth.user.username + "/" + e));
  };

  const intermediateProjectLoad = (e) => {
    console.log("clicked " + e);
    history.push(encodeURI("/intermediate/" + Auth.user.username + "/" + e));
  };

  const advanceProjectLoad = (e) => {
    console.log("clicked " + e);
    history.push(encodeURI("/advance/" + Auth.user.username + "/" + e));
  };

  const filterBasic = (arr, query) => {
    return arr.filter(
      (el) => el.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  };

  const searchFunction = (e) => {
    if (
      document.getElementById("search-input").value === "" ||
      document.getElementById("search-input").value === " " ||
      document.getElementById("search-input").value === "   "
    ) {
      setBasicProjects(basicogprojects);
      setIntermediateProjects(intermediateogprojects);
      setAdvanceProjects(advanceogprojects);
    } else {
      let bt = filterBasic(
        basicogprojects,
        document.getElementById("search-input").value
      );
      let it = filterBasic(
        intermediateogprojects,
        document.getElementById("search-input").value
      );
      let at = filterBasic(
        advanceogprojects,
        document.getElementById("search-input").value
      );
      setBasicProjects(bt);
      setIntermediateProjects(it);
      setAdvanceProjects(at);
    }
  };

  async function signOut() {
    try {
      await Auth.signOut({ global: true });
      window.location.reload();
    } catch (error) {
      // console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <div id="license-bg">
        <div
          style={{
            display: "flex",
            height: "8vh",
            justifyContent: "center",
            paddingTop: "5vh",
          }}
        >
          <h3
            style={{
              display: "flex",
              width: "90%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Enter Coupon below and click on submit.
          </h3>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={handleLicenseClose}
          >
            <h4>X</h4>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "-1vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input type="text" id="license_input" />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "2vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={licenseLoading}
            startIcon={licenseLoading ? <CircularProgress /> : <></>}
            onClick={submitLicnse}
          >
            submit
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "2vh",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>3 Months License - ₹ 999</h4>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "5vw" }}
              onClick={() => {
                window.open("https://rzp.io/l/enablarcode-3months");
              }}
            >
              Buy
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>6 Months License - ₹ 1699</h4>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "5vw" }}
              onClick={() => {
                window.open("https://rzp.io/l/enablarcode-6months");
              }}
            >
              Buy
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>12 Months License - ₹ 2499</h4>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "5vw" }}
              onClick={() => {
                window.open("https://rzp.io/l/enablarcode-12months");
              }}
            >
              Buy
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <span>Hello, {Auth.user.username}</span>
            {license === "loading" ? (
              <>
                <span>&nbsp;| Loading...</span>
              </>
            ) : (
              <></>
            )}
            {license === "free" ? (
              <>
                <span>&nbsp;| Free user &nbsp;&nbsp;</span>
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: "#26BC18" }}
                  onClick={showLicense}
                >
                  Add Coupon
                </Button>
              </>
            ) : (
              <></>
            )}
            {license === "premium" ? (
              <>
                <span>&nbsp;| Premium</span>
                <span>&nbsp; | Validity - {expiryDate}</span>
                <a
                  href="/learn"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <span>&nbsp; | Learn</span>
                </a>
              </>
            ) : (
              <></>
            )}
            <Typography variant="h6" className={classes.title}></Typography>
            {/* <Button  variant="contained" style={{backgroundColor:"#FF9900",color:"white"}} onClick={() =>{ddtest()}} >Test</Button> */}
            <Button
              variant="contained"
              style={{ backgroundColor: "#FF9900", color: "white" }}
              onClick={() => {
                signOut();
              }}
            >
              Signout
            </Button>
          </Toolbar>
        </AppBar>
      </div>

      <div className="project-bg"></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5vh",
        }}
      >
        <input
          id="search-input"
          type="text"
          placeholder="Enter project name to search"
          onChange={searchFunction}
        />
      </div>

      <div id="delete-bg">
        <div
          style={{
            display: "flex",
            height: "8vh",
            justifyContent: "center",
            paddingTop: "5vh",
          }}
        >
          <h3
            style={{
              display: "flex",
              width: "90%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Are you sure you want to delete {projectToDelete}?
          </h3>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={handleQRmodalClose}
          >
            <h4>X</h4>
          </span>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "5vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              deleteProject();
            }}
          >
            Yes
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={handleQRmodalClose}
          >
            No
          </Button>
        </div>
      </div>

      <div>
        <div className="project-div">
          <div
            id="create-new-basic"
            onClick={() => {
              history.push("/basicnew");
            }}
          >
            <b>New Project</b>
          </div>
          <h2 className="project-header basicheader">
            BASIC
            {/* <span className='basicnew' onClick={() => { history.push("/basicnew") }}>New</span> */}
          </h2>

          {isLoading ? (
            <>
              <div className="loading-project">
                <h5 className="project-title">Loading..</h5>
              </div>
            </>
          ) : (
            <>
              {basicprojects.map((item) => (
                <div key={item} className="project-body basicheader">
                  <div
                    className="delete-option basic-delete-options"
                    onClick={() => {
                      basicProjectDelete(item);
                    }}
                  >
                    <DeleteIcon
                      onClick={() => {
                        basicProjectDelete(item);
                      }}
                    />
                  </div>
                  <h6
                    className="project-title"
                    onClick={() => {
                      basicProjectLoad(item);
                    }}
                  >
                    {item}
                  </h6>
                  {/* <div className="view-option basic-view-options" onClick={() => { basicProjectLoad(item) }}>
                                        <VisibilityIcon onClick={() => { basicProjectLoad(item) }} />
                                    </div> */}
                </div>
              ))}
            </>
          )}
          <div style={{ paddingBottom: "30px" }}></div>
        </div>
        <div className="project-div">
          <div
            id="create-new-intermediate"
            onClick={() => {
              history.push("/intermediatenew");
            }}
          >
            <b>New Project</b>
          </div>
          <h2 className="project-header intermediateheader">
            INTERMEDIATE
            {/* <span className='intermediatenew' onClick={() => { history.push("/intermediatenew") }}>New</span> */}
          </h2>
          {isLoading ? (
            <>
              <div className="loading-project">
                <h5 className="project-title">Loading..</h5>
              </div>
            </>
          ) : (
            <>
              {intermediateprojects.map((item) => (
                <div key={item} className="project-body intermediateheader">
                  <div
                    className="delete-option intermediate-delete-options"
                    onClick={() => {
                      basicProjectDelete(item);
                    }}
                  >
                    <DeleteIcon
                      onClick={() => {
                        basicProjectDelete(item);
                      }}
                    />
                  </div>
                  <h6
                    className="project-title"
                    onClick={() => {
                      intermediateProjectLoad(item);
                    }}
                  >
                    {item}
                  </h6>
                  {/* <div className="view-option intermediate-view-options" onClick={() => { intermediateProjectLoad(item) }}>
                                        <VisibilityIcon onClick={() => { intermediateProjectLoad(item) }} />
                                    </div> */}
                </div>
              ))}
            </>
          )}
          <div style={{ paddingBottom: "30px" }}></div>
        </div>
        <div className="project-div">
          <div
            id="create-new-advance"
            onClick={() => {
              history.push("/advancenew");
            }}
          >
            <b>New Project</b>
          </div>
          <h2 className="project-header advanceheader">
            ADVANCE
            {/* <span className='advancenew' onClick={() => { history.push("/advancenew") }}>New</span> */}
          </h2>
          {isLoading ? (
            <>
              <div className="loading-project">
                <h5 className="project-title">Loading..</h5>
              </div>
            </>
          ) : (
            <>
              {advanceprojects.map((item) => (
                <div key={item} className="project-body advanceheader">
                  <div
                    className="delete-option advance-delete-options"
                    onClick={() => {
                      basicProjectDelete(item);
                    }}
                  >
                    <DeleteIcon
                      onClick={() => {
                        basicProjectDelete(item);
                      }}
                    />
                  </div>
                  <h6
                    className="project-title"
                    onClick={() => {
                      advanceProjectLoad(item);
                    }}
                  >
                    {item}
                  </h6>
                  {/* <div className="view-option advance-view-options" onClick={() => { advanceProjectLoad(item) }}>
                                        <VisibilityIcon onClick={() => { advanceProjectLoad(item) }} />
                                    </div> */}
                </div>
              ))}
            </>
          )}
          <div style={{ paddingBottom: "30px" }}></div>
        </div>
      </div>
    </>
  );
};

export default Home;
