import React, { Component, useEffect, useState } from 'react';
import { listEnablarProjects } from '../../graphql/queries';
import "./AdvanceNew.css";
import DesignToolBar from '../BussinessLogic/DesignToolBar';
import { SaveCodeAdvance } from '../BussinessLogic/SaveCode';
import {saveShortURL} from '../BussinessLogic/ShortURL';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import QRCode from 'qrcode.react';
import CircularProgress from '@material-ui/core/CircularProgress';


import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "50px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const AdvanceNew = props => {

  let uname = Auth.user.username;
  
  const history = useHistory();
  const classes = useStyles();

  const [currentView, setCurrentView] = useState("design");

  const [showQRModal, setQRmodalShow] = useState(false);
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [codeSaved, setCodeSaved] = useState(false);
  const [status, setCurrentStatus] = useState("none");
  const [runStatus, setRunStatus] = useState(false);
  const [pname,setPname] = useState("");
  const [projectid, setProjectID] = useState('');


  const handleQRmodalClose = () => { setQRmodalShow(false); document.getElementById("qrmodal").style.display = "none" };
  const handleSURLChange = (pid) => {setProjectID(pid)}

  useEffect(() => {
    setTimeout(() => { setDesignView(); document.getElementById("previewgif").style.filter = "opacity(0)"; document.getElementById("previewgif").style.zIndex = "-5" }, 4000);
  }, []);

  var codeDate = `<!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    
            <link rel="stylesheet" href="https://prismjs.com/themes/prism.css">
            <link rel="stylesheet" href="https://prismjs.com/plugins/line-numbers/prism-line-numbers.css">
            <link rel="stylesheet" href="https://live.prismjs.com/prism-live.css">
            <link rel="stylesheet" href="https://live.prismjs.com/style.css">
        <style>
            foo {
                background: black;
            }
        </style>
    
            <!-- Bootstrap CSS -->
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    
        </head>
    
    <body onload="myFunction()">
    <textarea id="code" class="prism-live line-numbers language-js" placeholder="// <------ Write your code here ------->"></textarea>
    
    
    
    <script src="https://blissfuljs.com/bliss.shy.min.js"></script>
    <script src="https://prismjs.com/prism.js"></script>
    <script src="https://prismjs.com/plugins/line-numbers/prism-line-numbers.js"></script>
    <script src="https://live.prismjs.com/src/prism-live.js"></script>
    <script src="https://live.prismjs.com/src/prism-live-markup.js"></script>
    <script src="https://live.prismjs.com/src/prism-live-css.js"></script>
    <script src="https://live.prismjs.com/src/prism-live-javascript.js"></script>
    <script src="https://live.prismjs.com/index.js"></script>
    <script src="/assets/src/apilocal.js"></script>


    <script>
    document.getElementById("code").value = "// <------ Write your code here ------->" ;
    </script>

    <script>
        function runcode() 
        {
            var code = document.getElementById("code").value;
            return code
        }
        function savecode() 
        {
            var code = document.getElementById("code").value;
            return code
        }
        
    </script>
    
    <script>
          var myintVar;
    
          function myFunction() {
            blockloadinterval();
            // myintVar =setInterval(blockloadinterval,1000);
          }
    
          function blockloadinterval()
          {
            
            try{
                document.getElementById("code").value = localStorage.getItem('edoc');            
                clearInterval(myintVar);
            }
            catch(err)
            {
              console.log(err);
            }
          }
          </script>
    
    
    </body>
    
    </html>
    `;


    var iframeDesign = `
    <html>
      <head><meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">   
      
          <!-- Bootstrap CSS -->
          <!-- Bootstrap CSS -->
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    
         
        <script src="/assets/src/aframe.min.js"></script>
        <script src="/assets/src/aframe-environment-component.min.js"></script>
        
        <script src="/assets/src/aframe-text-geometry-component.min.js"></script>
        <script src="/assets/src/aframe-extras.min.js"></script>

        
        <script src="/assets/src/aframe-physics-extras.min.js"></script>
        <script src="/assets/src/aframe-physics-system.min.js"></script>

        <!-- <script src="/assets/src/a.js"></script>
        <script src="/assets/src/aframe-inspector.min.js"></script>
         <script src="/assets/src/a.js"></script>
        <script src="https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js"></script> 
        <script src="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js"></script>-->
        
  
      
        <script>
              
          function TextIncrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value++;
          }
          function TextDecrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value--;
          }
        </script>
  <style>
    /* Center the loader */
    #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    /* Add animation to "page content" */
    .animate-bottom {
      position: relative;
      -webkit-animation-name: animatebottom;
      -webkit-animation-duration: 1s;
      animation-name: animatebottom;
      animation-duration: 1s
    }
    
    @-webkit-keyframes animatebottom {
      from { bottom:-100px; opacity:0 } 
      to { bottom:0px; opacity:1 }
    }
    
    @keyframes animatebottom { 
      from{ bottom:-100px; opacity:0 } 
      to{ bottom:0; opacity:1 }
    }
    
    #myDiv {
      display: none;
      text-align: center;
    }
    </style>
        <style>
          body {
            background-color:#ffffff;
            font-family: sans-serif;
          }
          h1 {
            font-weight: normal;
            font-size: 140%;
          }
          a-scene {
            height: 100vh;
            width: 100%;
          }
          
        </style>
      </head>
  <body onload="myFunction()">
        
  <div id="loader"></div>
  
  
  <section id="aframe-section">
        <a-scene id="Default-scene" light="defaultLightsEnabled: false" physics="debug: false;">
  
          <a-assets id="asset">
  
          </a-assets>
        
          <a-entity id="Main Parent"  >
            <a-entity id="Light 1" light="type: ambient; color: #BBB;"></a-entity>
          <a-entity id="Light 2" light="type: directional; color: #BBB; intensity: 0.4" position="-0.5 1 1"></a-entity>
            <a-entity id="rig" movement-controls="enabled:false" position="0 2.2 4">
            <a-entity id="Camera-entities" camera look-controls>
                        <a-entity id="Camera-controls" animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
                          animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
                          animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
                          cursor="fuse: true;"
                          material="color: cyan; shader: flat"
                          position="0 0 -3"
                          geometry="primitive: ring ; radiusInner: 0.10; radiusOuter: 0.15;"></a-entity>
              </a-entity>
            </a-entity>
              
        <a-sky id="Default-sky" position="0 0 1.50303" rotation="-0.3202834074781302 -2.4499675319794 -1.3584829322551817" color="gray"></a-sky>
      
        </a-entity>
        </a-scene>
  
  </section>
  
  
      <script>
  
        function runcode(code) 
            {
              try
              { 
                eval(code);
              } 
              catch(e) 
              {
                console.log(code);
                alert(e);
              }
            }
  
          function close_fcking_inspector()
          {
              setTimeout(function(){ if(window.parent.document.getElementById('intermediate_iframe_block').style.display == "none")
                  { var my_awesome_script = document.createElement('script');
                   // my_awesome_script.setAttribute('src',"assets/src/aframe-inspector.min.js");
                    my_awesome_script.setAttribute('src',"https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js");
  
                    
                    document.head.appendChild(my_awesome_script); }},2000);
          }
  
          function loadInspector()
          {
            var my_awesome_script = document.createElement('script');
            my_awesome_script.setAttribute('src',"/assets/src/a.js");
            //my_awesome_script.setAttribute('src',"https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js");          
            document.head.appendChild(my_awesome_script); 
          }
  
          function loadInspectorContent()
          {

            document.getElementById('Main Parent').remove();

            var parent = document.getElementById('Default-scene');                    
            var aentity = document.createElement('a-entity');
            aentity.setAttribute('id','Main Parent');
            parent.appendChild(aentity);

            document.getElementById('Main Parent').innerHTML = localStorage.getItem('lmth').split("/////")[0];

            try{
              if(localStorage.getItem('lmth').split("/////")[1].length > 1)
              {
               console.log("length "+localStorage.getItem('lmth').split("/////")[1].length);
               var evv = document.createElement('a-entity');
               evv.setAttribute('id','environment');
               evv.setAttribute('environment', 'preset:' + localStorage.getItem('lmth').split("/////")[1] );
               parent.appendChild(evv);
              }
           }
            catch{
              console.log('no env');
             
            }

            
            var my_awesome_script = document.createElement('script');
            my_awesome_script.setAttribute('src',"/assets/src/a.js");
            document.head.appendChild(my_awesome_script); 


            
            AFRAME.INSPECTOR.initUI();
          }
  
      </script>
      <script>
        var myVar;
        
        function myFunction() {
          // loadInspector();
          //document.getElementById("aframe-section").innerHTML = localStorage.getItem('src');
          myVar = setTimeout(showPage, 3000);
        }
        
        function showPage() {
          //loadInspector();
          document.getElementById("loader").style.display = "none";
          if(localStorage.getItem("lmth"))
          {
            console.log("here in content" + localStorage.getItem('lmth'));
            loadInspectorContent();
          }
          else
          {
            console.log("here in no content");
            loadInspector();
          }
        }
        </script>
  
  
  
      </body>
      </html>
  
  `;

  var aframeOut = `
    <html>
      <head><meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      
          <!-- Bootstrap CSS -->
          <!-- Bootstrap CSS -->
         
        <script src="/assets/src/aframe.min.js"></script>
        <script src="/assets/src/aframe-environment-component.min.js"></script>
        
        <script src="/assets/src/aframe-text-geometry-component.min.js"></script>
        <script src="/assets/src/aframe-extras.min.js"></script>
        
        <!--<script src="/assets/src/aframe-inspector.min.js"></script> -->
  
        <!-- <script src="https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js"></script> -->
  
        <script src="/assets/src/apilocal.js"></script>
        <script src="/assets/src/aframe-physics-extras.min.js"></script>
        <script src="/assets/src/aframe-physics-system.min.js"></script>
      
        <script>
              
          function TextIncrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value++;
          }
          function TextDecrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value--;
          }
        </script>
  <style>
    /* Center the loader */
    #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    /* Add animation to "page content" */
    .animate-bottom {
      position: relative;
      -webkit-animation-name: animatebottom;
      -webkit-animation-duration: 1s;
      animation-name: animatebottom;
      animation-duration: 1s
    }
    
    @-webkit-keyframes animatebottom {
      from { bottom:-100px; opacity:0 } 
      to { bottom:0px; opacity:1 }
    }
    
    @keyframes animatebottom { 
      from{ bottom:-100px; opacity:0 } 
      to{ bottom:0; opacity:1 }
    }
    
    #myDiv {
      display: none;
      text-align: center;
    }
    </style>
        <style>
          body {
            background-color:#ffffff;
            font-family: sans-serif;
          }
          h1 {
            font-weight: normal;
            font-size: 140%;
          }
          a-scene {
            height: 100vh;
            width: 100%;
          }
          
        </style>
      </head>
  <body onload="myFunction()">
        
  <div id="loader"></div>
  
  
  <section id="aframe-section">
        <a-scene id="Default-scene" light="defaultLightsEnabled: false" physics="debug: false;">
        
          
          <a-entity id="Main Parent"  >
              <a-entity id="Light 1" light="type: ambient; color: #BBB;"></a-entity>
              <a-entity id="Light 2" light="type: directional; color: #BBB; intensity: 0.4" position="-0.5 1 1"></a-entity>
              <a-entity id="rig" movement-controls="enabled:false" position="0 2.2 4">
            <a-entity id="Camera-entities" camera look-controls>
                        <a-entity id="Camera-controls" animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
                          animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
                          animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
                          cursor="fuse: true;"
                          material="color: cyan; shader: flat"
                          position="0 0 -3"
                          geometry="primitive: ring ; radiusInner: 0.10; radiusOuter: 0.15;"></a-entity>
              </a-entity>
            </a-entity>
                
          <a-sky id="Default-sky" position="0 0 1.50303" rotation="-0.3202834074781302 -2.4499675319794 -1.3584829322551817" color="gray"></a-sky>
        
          </a-entity>
        </a-scene>
  
  </section>
  
  
      <script>
  
        function runcode(code) 
            {
              try
              { 
                eval("var score = 0;"+code);
              } 
              catch(e) 
              {
                console.log(code);
                alert(e);
              }
            }
        
        function myFunction() {
          myVar = setTimeout(showPage, 3000);
        }
        
        function showPage() {
          document.getElementById("loader").style.display = "none";
        }
        </script>
  
  
    </body>
  </html>
  
  `;

  const toggleToCode = () => {
    document.getElementById("advance_iframe_aframe").contentDocument.getElementById('Main Parent').flushToDOM(true);
    var x = document.getElementById("advance_iframe_aframe").contentDocument.getElementById("Main Parent");
    document.getElementById("advance_iframe_aframe_out").contentDocument.getElementById("Main Parent").innerHTML = x.innerHTML;
    let env_available = false;
    try {
      var x = document.getElementById("advance_iframe_aframe").contentDocument.getElementById("environment");
      var preset = x.getAttribute('environment').preset;
      env_available = true;
    }
    catch {
      env_available = false;
    }
    try {
      if (env_available) {
        var x = document.getElementById("advance_iframe_aframe").contentDocument.getElementById("environment");
        var preset = x.getAttribute('environment').preset;
        var env = document.getElementById("advance_iframe_aframe_out").contentDocument.getElementById('environment');
        env.remove();
        var parent = document.getElementById("advance_iframe_aframe_out").contentDocument.getElementById('Default-scene');
        var aentity = document.getElementById("advance_iframe_aframe_out").contentDocument.createElement('a-entity');
        aentity.setAttribute('id', 'environment');
        aentity.setAttribute('environment', `preset: ${preset}`);
        parent.appendChild(aentity);
      }
    }
    catch {
      var parent = document.getElementById("advance_iframe_aframe_out").contentDocument.getElementById('Default-scene');
      var aentity = document.getElementById("advance_iframe_aframe_out").contentDocument.createElement('a-entity');
      aentity.setAttribute('id', 'environment');
      aentity.setAttribute('environment', `preset: ${preset}`);
      parent.appendChild(aentity);
    }
  }

  const runAdvanceCode = () => {
    document.getElementById('advance_iframe_aframe_out').contentWindow.location.reload(true);
    setTimeout(function () {
      toggleToCode();
      var iframe = document.getElementById("advance_iframe_block");
      var code = iframe.contentWindow.runcode();
      document.getElementById('advance_iframe_aframe_out').contentWindow.runcode(code);
    }, 2000);
  }
  const setDesignView = () => {
    document.getElementById("previewgif").style.transition = "all 0.1s ease-in-out";
    document.getElementById("previewgif").style.filter = "opacity(1)";
    document.getElementById("previewgif").style.zIndex = "99";
    document.getElementById("previewgif").style.transition = "all 0.25s ease-in-out";
    setCurrentView("design");
    setTimeout(() => {
      document.getElementById("advance_iframe_aframe").style.display = "flex";
      document.getElementById("advance_iframe_block").style.display = "none";
      document.getElementById("advance_iframe_block").style.left = "0px";
      document.getElementById("advance_iframe_aframe_out").style.display = "none";
      document.getElementById("advance_iframe_aframe_out").style.left = "52.1vw";
    }, 250);
    setTimeout(() => { document.getElementById("previewgif").style.filter = "opacity(0)"; document.getElementById("previewgif").style.zIndex = "-5" }, 1000);
  }

  const setCodingView = () => {
    document.getElementById("previewgif").style.transition = "all 0.1s ease-in-out";
    document.getElementById("previewgif").style.filter = "opacity(1)";
    document.getElementById("previewgif").style.zIndex = "99";
    document.getElementById("previewgif").style.transition = "all 0.25s ease-in-out";
    setCurrentView("code");
    setTimeout(() => {
      document.getElementById("advance_iframe_aframe").style.display = "none";
      document.getElementById("advance_iframe_block").style.display = "flex";
      document.getElementById("advance_iframe_block").style.left = "0px";
      document.getElementById("advance_iframe_aframe_out").style.display = "flex";
      document.getElementById("advance_iframe_aframe_out").style.left = "52.1vw";
      toggleToCode();
    }, 250);
    setTimeout(() => { document.getElementById("previewgif").style.filter = "opacity(0)"; document.getElementById("previewgif").style.zIndex = "-5" }, 1000);
  }

  const publishCode = () => {
    // if(codeSaved)
    // {
    //   setQRmodalShow(true); 
    //   document.getElementById("qrmodal").style.display = "block";
    // }
    // else
    // {
    //   alert("Please save your code");
    // }
    if(document.getElementById("project-name").value.trim() === "")
    {
      alert("please enter project name");
    }
    else if(localStorage.getItem(Auth.user.username) === "free")
    {
      alert("This is a free user account. Please purchase a premium coupon code to save and publish your projects.");
    }
    else{
      saveCode();
      setQRmodalShow(true); 
      document.getElementById("qrmodal").style.display = "block";
    }
  }

  const saveCode = async() => {
    if(document.getElementById("project-name").value.trim() === "")
    {
      alert("please enter project name");
    }
    else if(localStorage.getItem(Auth.user.username) === "free")
    {
      alert("This is a free user account. Please purchase a premium coupon code to save and publish your projects.");
    }
    else{
      setCurrentStatus("saving");
      setTimeout(() => {setCurrentStatus("saved")},4000);
      setTimeout(() => { setCurrentStatus("") }, 8000);
      let sc = SaveCodeAdvance({ "username": Auth.user.username, "projectname": document.getElementById("project-name").value.trim() }); 
      setPname(document.getElementById("project-name").value.trim());
      setCodeSaved(sc);let surlinternal = await saveShortURL('create', uname+ "-"+document.getElementById("project-name").value.trim());
      surlinternal = JSON.parse(surlinternal);
      if (surlinternal['status'] === "success") {
        handleSURLChange(surlinternal['projectid']);
      }
      else{
        console.log("EEROEOREOROE");
      }
    }
  }

  return (
    <>
    <div id='qrmodal'>
        <div style={{ display: "flex", height: "8vh", justifyContent: "center" }}>
          <span style={{ display: "flex", width: "90%", alignItems: "center", justifyContent: "center" }}>Scan the QR Code or click on the button to open your in new tab</span>
          <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer" }} onClick={handleQRmodalClose}><h4>X</h4></span>
        </div>

        <hr />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5vh" }}>
          {/* <QRCode value={encodeURI(`https://api.enablar.co/dev/play?token=${uname}-${codeSaved?document.getElementById("project-name").value.trim():pname}`)} size={172} /> */}
          <QRCode value={encodeURI(`https://m.enablar.co/${projectid}`)} size={172} />
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {/* <a target="_blank" href={encodeURI(`https://api.enablar.co/dev/play?token=${uname}-${codeSaved?document.getElementById("project-name").value.trim():pname}`)} > */}
          <a target="_blank" href={encodeURI(`https://m.enablar.co/${projectid}`)} >
            <Button variant='contained' color="primary">
              Open in new tab
            </Button>
          </a>
          &nbsp;
          {/* <Button variant='contained' color="primary" onClick={() => { setLinkCopied(true);setTimeout(() => {setLinkCopied(false)},4000) ; navigator.clipboard.writeText(encodeURI(`https://api.enablar.co/dev/play?token=${uname}-${codeSaved?document.getElementById("project-name").value.trim():pname}`)) }}>
            Copy link
          </Button> */}
          <Button variant='contained' color="primary" onClick={() => { setLinkCopied(true);setTimeout(() => {setLinkCopied(false)},4000) ; navigator.clipboard.writeText(encodeURI(`https://m.enablar.co/${projectid}`)) }}>
            Copy link
          </Button>
          {linkCopied ?
            <Alert onClose={() => { setLinkCopied(false) }}>Link copied</Alert>
            :
            <></>
          }
        </div>
        {/* <div style={{ marginLeft: "18vw", paddingTop: "2vh" }}>
                    <Button variant="primary" onClick={handleQRmodalClose}>
                        Close
                    </Button>
                </div> */}
      </div>
      <img id="previewgif" src="/assets/images/preview.gif" ></img>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar >
            {currentView === "design" ?
              <>
                <Button variant='contained' onClick={() => { history.push("/") }} style={{ cursor: "pointer", marginTop: "-12px", backgroundColor:"#00A86B",color:"white" }}>
                  Home
                </Button>
                <span style={{ position: "relative", top: "-7px" }}>&nbsp;&nbsp;&nbsp;</span>
                <input placeholder='Enter project name' type="text" id="project-name" style={{ position: "relative", top: "-6px" }} />
                <span style={{ position: "relative", top: "-7px" }}>&nbsp;&nbsp;&nbsp;</span>
                <Button color="inherit" style={{ position: "relative", top: "-4px",backgroundColor:"#00A86B",color:"white" }} disabled={status==="saving"? true:false} onClick={saveCode}>Save</Button>
                {status === "saving" ?
                <span style={{ position: "relative", top: "-10px", opacity: "0.5",marginLeft:"6px" }} > <CircularProgress style={{ color: "white", position: "relative", top: "5px", opacity: "0.5", width: "20px", height: "20px" }} />&nbsp;saving..</span>
                : <></>}
                {status === "saved" ?
                  <span style={{ position: "relative", top: "-6px", opacity: "0.5",marginLeft:"6px" }} >saved</span>
                  : <></>}
                <div style={{ position: "absolute", right: "2vw", top: "10px" }}>
                  <Button variant="contained" style={{backgroundColor:"#FF8C1A",color:"white"}} onClick={setCodingView}>Code</Button>
                </div>
                <DesignToolBar type="advance" />
              </>
              :
              <>
                <Button variant='contained' onClick={() => { history.push("/") }} style={{ cursor: "pointer", marginTop: "-12px", backgroundColor:"#00A86B",color:"white" }}>
                  Home
                </Button>
                <span style={{ position: "relative", top: "-7px" }}>&nbsp;&nbsp;&nbsp;</span>
                <input type="text" id="project-name" style={{ position: "relative", top: "-6px" }} />
                <span style={{ position: "relative", top: "-7px" }}>&nbsp;&nbsp;&nbsp;</span>
                <Button color="inherit" style={{ position: "relative", top: "-4px",backgroundColor:"#00A86B",color:"white" }} disabled={status==="saving"? true:false} onClick={saveCode}>Save</Button>
                {status === "saving" ?
                <span style={{ position: "relative", top: "-10px", opacity: "0.5",marginLeft:"6px" }} > <CircularProgress style={{ color: "white", position: "relative", top: "5px", opacity: "0.5", width: "20px", height: "20px" }} />&nbsp;saving..</span>
                : <></>}
                {status === "saved" ?
                  <span style={{ position: "relative", top: "-6px", opacity: "0.5",marginLeft:"6px" }} >saved</span>
                  : <></>}
                <div style={{ position: "absolute", right: "2vw", top: "10px" }}>
                  <Button variant="contained" style={{backgroundColor:"#FF8C1A",color:"white"}} onClick={setDesignView}>Design</Button>
                <span style={{ position: "relative", top: "-7px" }}>&nbsp;&nbsp;&nbsp;</span>
                  <Button variant="contained" style={{backgroundColor:"#FF8C1A",color:"white"}} onClick={runAdvanceCode}>Run</Button>
                <span style={{ position: "relative", top: "-7px" }}>&nbsp;&nbsp;&nbsp;</span>
                  <Button variant="contained" style={{backgroundColor:"#FF8C1A",color:"white"}} onClick={publishCode}>Publish</Button>
                </div>
              </>
            }
          </Toolbar>
        </AppBar>
      </div>

      <section >
        <iframe className="border-white" id="advance_iframe_aframe" srcDoc={iframeDesign} > </iframe>
        <iframe className="border-white" id="advance_iframe_block" srcDoc={codeDate}  > </iframe>
        <iframe className="border-white" id="advance_iframe_aframe_out" srcDoc={aframeOut} ></iframe>
      </section>
    </>
  );
}

export default AdvanceNew;