/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEnablARCodeUsers = /* GraphQL */ `
  query GetEnablARCodeUsers($User: String!) {
    getEnablARCodeUsers(User: $User) {
      User
      expiry
      type
    }
  }
`;
export const getEnablarProject = /* GraphQL */ `
  query GetEnablarProject($id: String!) {
    getEnablarProject(id: $id) {
      difficulty
      ef1
      html
      id
      runcode
      tk
      xml
    }
  }
`;

export const listEnablarProjects = /* GraphQL */ `
  query ListEnablarProjects(
    $filter: TableEnablarProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnablarProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        difficulty
        ef1
        html
        id
        runcode
        tk
        xml
      }
      nextToken
    }
  }
`;
