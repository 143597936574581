
export const AdvanceLessons = [
    {
      "title": [
        "Level 1",
        "5 Lectures/ 23 mins"
      ],
      "content": [
      
        {
          "title": "L1 - Basics of HTML",
          "link": "https://youtu.be/P2edU7QuSaE",
          "duration": "3.14"
        },
         {
          "title": "L2 - Creating a webpage",
          "link": "https://youtu.be/cJY0tTOq-MY",
          "duration": "6.31"
        },
         {
          "title": "L3 - Basic of CSS",
          "link": "https://youtu.be/BjHi958cy_U",
          "duration": "3.55"
        },
         {
          "title": "L4 - Adding style using CSS",
          "link": "https://youtu.be/IGC6e3-R_c0h",
          "duration": "4.35"
        },
         {
          "title": "L5 - Relationship between HTML CSS and JS",
          "link": "https://youtu.be/07vpWBGymwM",
          "duration": "2.15"
        }
      ]
    },
  
  
  {
      "title": [
        "Level 2",
        "4 Lectures/ 10 mins"
      ],
      "content": [
        {
          "title": "L1 - Introduction to Vs code",
          "link": "https://youtu.be/c5WLR2rS37M",
          "duration": "3.52"
        },
        {
          "title": "L2 -How to install node.JS",
          "link": "https://youtu.be/obtZE2aUP3U",
          "duration": "2.03"
        },
        {
          "title": "L3 -How to install extensions to run JS code",
          "link": "https://youtu.be/W4UDrhJ21m4",
          "duration": "1.11"
        },
        {
          "title": "L4 -How to write a code and view output",
          "link": "https://youtu.be/4u75jL9yvrw",
          "duration": "1.48"
        }
      ]
    },
  
  {
      "title": [
        "Level 3",
        "6 Lectures/ 36 mins"
      ],
      "content": [
        {
          "title": "L1 - How to create Variables",
          "link": "https://youtu.be/RCIrl95kvwQ",
          "duration": "6.47"
        },
        {
          "title": "L2 - How to add constants",
          "link": "https://youtu.be/xJGAMYx6NS8",
          "duration": "3.14"
        },
        {
          "title": "L3 - Creating functions",
          "link": "https://youtu.be/4NnV40zAan8",
          "duration": "7.45"
        },
        {
          "title": "L4 - Types of functions",
          "link": "https://youtu.be/2tjlHfIauhY",
          "duration": "4.2"
        },
        {
          "title": " L5 - Introduction to Objects",
          "link": "https://youtu.be/DJp-TDEyb-o",
          "duration": "7.12"
        },
        {
          "title": "L6 - Introduction to Arrays",
          "link": "https://youtu.be/cNjOKcNw_2Y",
          "duration": "6.5"
        }
      ]
    },
  
  {
      "title": [
        "Level 4",
        "5 Lectures/ 23 mins"
      ],
      "content": [
        {
          "title": "L1 - Javascript operators",
          "link": "https://youtu.be/4Pek_nW6H18",
          "duration": "1.35"
        },
        {
          "title": "L2 - Arithematic operator",
          "link": "https://youtu.be/ilhEUEEoDu8",
          "duration": "7.25"
        },
        {
          "title": "L3 - Assignment operator",
          "link": "https://youtu.be/jb9Njdkb8-E",
          "duration": "4.18"
        },
        {
          "title": "L4 - Equality operators",
          "link": "https://youtu.be/1nXGIMissw8",
          "duration": "3.21"
        },
        {
          "title": "L5 - Logical operators with non boolean",
          "link": "https://youtu.be/L0cGNmMtKsc",
          "duration": "5.45"
        }
      ]
    },
  
  {
      "title": [
        "Level 5",
        "5 Lectures/ 18 mins"
      ],
      "content": [
        {
          "title": "L1 - If else",
          "link": "https://youtu.be/7gCJyIF_GbM",
          "duration": "3.1"
        },
        {
          "title": "L2 - switch case",
          "link": "https://youtu.be/yagDAMuSLL8",
          "duration": "2.56"
        },
        {
          "title": "L3 - for loop",
          "link": "https://youtu.be/MZANkKaRt9I",
          "duration": "6"
        },
        {
          "title": "L4 - while",
          "link": "https://youtu.be/8JBPRqIivfI",
          "duration": "2.53"
        },
        {
          "title": "L5 - do while",
          "link": "https://youtu.be/exmFKfblKmU",
          "duration": "1.41"
        }
      ]
    },
  
  {
      "title": [
        "Level 6",
        "5 Lectures/ 16 mins"
      ],
      "content": [
        {
          "title": "L1 - Adding elements in array",
          "link": "https://youtu.be/QXq83sg05jM",
          "duration": "3.48"
        },
        {
          "title": "L2 - Finding elements in array",
          "link": "https://youtu.be/ADR6JCCpmMA",
          "duration": "2.43"
        },
        {
          "title": "L3 - Removing elements",
          "link": "https://youtu.be/pdXfZEKmyIs",
          "duration": "2.33"
        },
        {
          "title": "L4 - Sorting an array",
          "link": "https://youtu.be/QIVpQ8aMaU4",
          "duration": "3.47"
        },
        {
          "title": "L5 - Mapping an array",
          "link": "https://youtu.be/b_-Cz0rb5qE",
          "duration": "2.45"
        }
      ]
    },
  
  {
      "title": [
        "Level 7",
        "4 Lectures/ 13 mins"
      ],
      "content": [
        {
          "title": "L1 - Introduction to Advanced Design section",
          "link": "https://youtu.be/m4yNXgfv6K0",
          "duration": "3.19"
        },
        {
          "title": "L2 - How to add 3D object",
          "link": "https://youtu.be/GKjsnQ4cNIQ",
          "duration": "2.49"
        },
        {
          "title": "L3 - Modify and align 3D object",
          "link": "https://youtu.be/GkOD8RiZjd4",
          "duration": "4.06"
        },
        {
          "title": "L4 - Import 3D model",
          "link": "https://youtu.be/eo__QAckrBE",
          "duration": "2.19"
        }
      ]
    },
  
  {
      "title": [
        "Level 8",
        "5 Lectures/ 32 mins"
      ],
      "content": [
        {
          "title": "L1 - High Bounce",
          "link": "https://youtu.be/j6HVx4NCqcA",
          "duration": "8.05"
        },
        {
          "title": "L2 - Physics collision",
          "link": "https://youtu.be/Ymo6JPiGoLk",
          "duration": "5.46"
        },
        {
          "title": "L3 - Implementing friction",
          "link": "https://youtu.be/Mq1CmCUkP9g",
          "duration": "4.25"
        },
        {
          "title": "L4 - Fetching values using things speak",
          "link": "https://youtu.be/P8QZW5SwDBc",
          "duration": "4.30"
        },
        {
          "title": "L5 - Shooting game",
          "link": "https://youtu.be/yEhYPtnn2Hg",
          "duration": "9.16"
        }
      ]
    }

];

export const ALesson1 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 1</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 23 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=P2edU7QuSaE" style={{color:"black"}} > L1 - Basics of HTML </a></div>
                            <div style={{float:"right",color:"grey"}}>3:14 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=cJY0tTOq-MY" style={{color:"black"}} > L2 - Creating a webpage </a></div>
                            <div style={{float:"right",color:"grey"}}>6:31 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=BjHi958cy_U" style={{color:"black"}} > L3 - Basic of CSS </a></div>
                            <div style={{float:"right",color:"grey"}}>2:55 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=IGC6e3-R_c0" style={{color:"black"}} > L4 - Adding style using CSS </a></div>
                            <div style={{float:"right",color:"grey"}}>4:35 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=07vpWBGymwM" style={{color:"black"}} > L5 - Relationship between HTML CSS and JS </a></div>
                            <div style={{float:"right",color:"grey"}}>2:15 mins</div> 
                        </li>                              
                    </ul>
                </div>
);


export const ALesson2 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 2</b> </div>
                            <div style={{float:"right",color:"grey"}}>4 lectures/ 10 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=c5WLR2rS37M" style={{color:"black"}} > L1 - Introduction to Vs code </a></div>
                            <div style={{float:"right",color:"grey"}}>3:52 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=obtZE2aUP3U" style={{color:"black"}} > L2 - How to install node.JS </a></div>
                            <div style={{float:"right",color:"grey"}}>2:03 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=W4UDrhJ21m4" style={{color:"black"}} > L3 - How to install extensions to run JS code </a></div>
                            <div style={{float:"right",color:"grey"}}>1:11 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=4u75jL9yvrw" style={{color:"black"}} > L4 - How to write a code and view output </a></div>
                            <div style={{float:"right",color:"grey"}}>1:48 mins</div> 
                        </li>                                                                      
                    </ul>
                </div>
);

export const ALesson3 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 3</b> </div>
                            <div style={{float:"right",color:"grey"}}>6 lectures/ 36 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=RCIrl95kvwQ" style={{color:"black"}} > L1- How to create Variables </a></div>
                            <div style={{float:"right",color:"grey"}}>6:47 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=xJGAMYx6NS8" style={{color:"black"}} > L2 - How to add constants </a></div>
                            <div style={{float:"right",color:"grey"}}>3:14 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=4NnV40zAan8" style={{color:"black"}} > L3 - Creating functions </a></div>
                            <div style={{float:"right",color:"grey"}}>7:45 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=2tjlHfIauhY" style={{color:"black"}} > L4 - Types of functions </a></div>
                            <div style={{float:"right",color:"grey"}}>4:20 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=DJp-TDEyb-o" style={{color:"black"}} > L5 - Introduction to Objects </a></div>
                            <div style={{float:"right",color:"grey"}}>7:12 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=cNjOKcNw_2Y" style={{color:"black"}} > L6 - Introduction to Arrays </a></div>
                            <div style={{float:"right",color:"grey"}}>6:50 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ALesson4 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 4</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 23 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=4Pek_nW6H18" style={{color:"black"}} > L1 - Javascript operators </a></div>
                            <div style={{float:"right",color:"grey"}}>1:35 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ilhEUEEoDu8" style={{color:"black"}} > L2 - Arithematic operator </a></div>
                            <div style={{float:"right",color:"grey"}}>7:25 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=jb9Njdkb8-E" style={{color:"black"}} > L3 - Assignment operator </a></div>
                            <div style={{float:"right",color:"grey"}}>4:18 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=1nXGIMissw8" style={{color:"black"}} > L4 - Equality operators </a></div>
                            <div style={{float:"right",color:"grey"}}>3:21 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=L0cGNmMtKsc" style={{color:"black"}} > L5 - Logical operators with non boolean </a></div>
                            <div style={{float:"right",color:"grey"}}>5:45 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ALesson5 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 5</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 18 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=7gCJyIF_GbM" style={{color:"black"}} > L1 - If else </a></div>
                            <div style={{float:"right",color:"grey"}}>3:10 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=yagDAMuSLL8" style={{color:"black"}} > L2 - switch case </a></div>
                            <div style={{float:"right",color:"grey"}}>2:56 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=MZANkKaRt9I" style={{color:"black"}} > L3 - for loop </a></div>
                            <div style={{float:"right",color:"grey"}}>6:00 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=8JBPRqIivfI" style={{color:"black"}} > L4 - while </a></div>
                            <div style={{float:"right",color:"grey"}}>2:53 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=exmFKfblKmU" style={{color:"black"}} > L5 - do while </a></div>
                            <div style={{float:"right",color:"grey"}}>1:41 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ALesson6 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 6</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 16 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=QXq83sg05jM" style={{color:"black"}} > L1 - Adding elements in array </a></div>
                            <div style={{float:"right",color:"grey"}}>3:48 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=ADR6JCCpmMA" style={{color:"black"}} > L2 - Finding elements in array </a></div>
                            <div style={{float:"right",color:"grey"}}>2:43 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=pdXfZEKmyIs" style={{color:"black"}} > L3 - Removing elements </a></div>
                            <div style={{float:"right",color:"grey"}}>2:33 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=QIVpQ8aMaU4" style={{color:"black"}} > L4 - Sorting an array </a></div>
                            <div style={{float:"right",color:"grey"}}>3:47 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=b_-Cz0rb5qE" style={{color:"black"}} > L5 - Mapping an array </a></div>
                            <div style={{float:"right",color:"grey"}}>2:45 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ALesson7 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 7</b> </div>
                            <div style={{float:"right",color:"grey"}}>4 lectures/ 13 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=m4yNXgfv6K0" style={{color:"black"}} > L1 - Introduction to Advanced Design section </a></div>
                            <div style={{float:"right",color:"grey"}}>3:19 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=GKjsnQ4cNIQ" style={{color:"black"}} > L2 - How to add 3D object </a></div>
                            <div style={{float:"right",color:"grey"}}>2:49 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=GkOD8RiZjd4" style={{color:"black"}} > L3 - Modify and align 3D object </a></div>
                            <div style={{float:"right",color:"grey"}}>4:06 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=eo__QAckrBE" style={{color:"black"}} > L4 - Import 3D model </a></div>
                            <div style={{float:"right",color:"grey"}}>2:19 mins</div> 
                        </li>
                    </ul>
                </div>
);

export const ALesson8 = () => (
    <div class="col-lg-8 col-sm-8 col-xs-12">
                    <ul class="list-group list-group-flush" style={{borderRadius:"15px",width:"55rem"}}>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",fontSize:"150%"}} >
                            <div style={{float:"left"}} > <b>Level 8</b> </div>
                            <div style={{float:"right",color:"grey"}}>5 lectures/ 3 mins</div>
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=j6HVx4NCqcA" style={{color:"black"}} > L1 - High Bounce </a></div>
                            <div style={{float:"right",color:"grey"}}>8:05 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Ymo6JPiGoLk" style={{color:"black"}} > L2 - Physics collision </a></div>
                            <div style={{float:"right",color:"grey"}}>5:46 mins</div> 
                        </li> 
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=Mq1CmCUkP9g" style={{color:"black"}} > L3 - Implementing friction </a></div>
                            <div style={{float:"right",color:"grey"}}>4:25 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=P8QZW5SwDBc" style={{color:"black"}} > L4 - Fetching values using things speak </a></div>
                            <div style={{float:"right",color:"grey"}}>4:30 mins</div> 
                        </li>
                        <li class="list-group-item" style={{backgroundColor:"rgba(255,255,255,0.7)",borderTop:"1.5px solid black"}} >                                
                            <div style={{float:"left"}}> <a class="nav-link" data-fancybox href="https://www.youtube.com/watch?v=yEhYPtnn2Hg" style={{color:"black"}} > L5 - Shooting game </a></div>
                            <div style={{float:"right",color:"grey"}}>9:16 mins</div> 
                        </li>
                    </ul>
                </div>
);