import React, { Component, useEffect, useState } from "react";
import { listEnablarProjects, getEnablarProject } from "../../graphql/queries";
import "./IntermediateLoad.css";
import DesignToolBar from "../BussinessLogic/DesignToolBar";
import { SaveCodeIntermediate } from "../BussinessLogic/SaveCode";
import { saveShortURL } from "../BussinessLogic/ShortURL";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "50px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const IntermediateLoad = (props) => {
  let { uname, pname } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [currentView, setCurrentView] = useState("design");

  const [showQRModal, setQRmodalShow] = useState(false);
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [codeSaved, setCodeSaved] = useState(false);
  const [status, setCurrentStatus] = useState("none");
  const [runStatus, setRunStatus] = useState(false);
  const [moveSide, setMoveSide] = useState("left");
  // const [ppname, setPname] = useState("");
  const [projectid, setProjectID] = useState("");

  const handleQRmodalClose = () => {
    setQRmodalShow(false);
    document.getElementById("qrmodal").style.display = "none";
  };

  const handleSURLChange = (pid) => {
    setProjectID(pid);
  };

  async function loadProject() {
    let tempd = await API.graphql({
      query: getEnablarProject,
      variables: { id: uname + "-" + pname },
    });

    try {
      document.getElementById("project-name").value = pname;
      // console.log("data : " + tempd.data.getEnablarProject["xml"]);
      localStorage.setItem(
        `${uname}-${pname}-lmx`,
        tempd.data.getEnablarProject["xml"]
      );
      localStorage.setItem(
        `${uname}-${pname}-lmth`,
        tempd.data.getEnablarProject["html"]
      );
    } catch (e) {
      document.getElementById("errorwindow").style.display = "block";
      console.log("error " + e);
    }
  }

  useEffect(async () => {
    loadProject();
    let surl = await saveShortURL("get", uname + "-" + pname);
    surl = JSON.parse(surl);
    if (surl["status"] === "success") {
      handleSURLChange(surl["projectid"]);
    } else {
      let surlinternal = await saveShortURL("create", uname + "-" + pname);
      surlinternal = JSON.parse(surlinternal);
      if (surlinternal["status"] === "success") {
        handleSURLChange(surlinternal["projectid"]);
      } else {
        console.log("EEROEOREOROE");
      }
    }
    setTimeout(() => {
      setDesignView();
      document.getElementById("previewgif").style.filter = "opacity(0)";
      document.getElementById("previewgif").style.zIndex = "-5";
    }, 4000);
  }, []);

  var blockData = `
    <html>
      <head><meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      
          <!-- Bootstrap CSS -->
          <!-- Bootstrap CSS -->
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
  
         
        <title>Enablar Code</title>
        <!-- <script src="bootstrap.min.css"></script> -->
        <script src="/assets/src/blockly_compressed.js"></script>
        <script src="/assets/src/blocks_compressed.js"></script>
        <script src="/assets/src/javascript_compressed.js"></script>
        <script src="/assets/src/en.js"></script>
        <script src="/assets/src/code.js"></script>
        <script src="/assets/src/IntermediateCustomblock.js"></script>
      
  <style>
    /* Center the loader */
    #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    /* Add animation to "page content" */
    .animate-bottom {
      position: relative;
      -webkit-animation-name: animatebottom;
      -webkit-animation-duration: 1s;
      animation-name: animatebottom;
      animation-duration: 1s
    }
    
    @-webkit-keyframes animatebottom {
      from { bottom:-100px; opacity:0 } 
      to { bottom:0px; opacity:1 }
    }
    
    @keyframes animatebottom { 
      from{ bottom:-100px; opacity:0 } 
      to{ bottom:0; opacity:1 }
    }
    
    #myDiv {
      display: none;
      text-align: center;
    }
    </style>
        <style>
          body {
            background-color:#ffffff;
            font-family: sans-serif;
          }
          h1 {
            font-weight: normal;
            font-size: 140%;
          }
          
        </style>
      </head>
      <body onload="myFunction()">
        
        <div id="loader"></div>
      <section>
      
     
      <div id="blocklyDiv" style="height: 100vh; width:100%;padding:0px;padding-left:1%"></div>
             
      <xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none">
      <category name="Logic" categorystyle="logic_category">
        <block type="controls_if"></block>
        <block type="logic_compare"></block>
        <block type="logic_operation"></block>
        <block type="logic_negate"></block>
        <block type="logic_boolean"></block>
      </category>
      <category name="Math" categorystyle="math_category">
        <block type="math_number" gap="32">
          <field name="NUM">123</field>
        </block>
        <block type="math_arithmetic">
          <value name="A">
            <shadow type="math_number">
              <field name="NUM">1</field>
            </shadow>
          </value>
          <value name="B">
            <shadow type="math_number">
              <field name="NUM">1</field>
            </shadow>
          </value>
        </block>
        <block type="math_random_int">
          <value name="FROM">
            <shadow type="math_number">
              <field name="NUM">1</field>
            </shadow>
          </value>
          <value name="TO">
            <shadow type="math_number">
              <field name="NUM">100</field>
            </shadow>
          </value>
        </block>
        <block type="math_random_float"></block>
      </category>
      <category name="Text" categorystyle="text_category">
        <block type="text"></block>
        <block type="set_text"></block>
        <block type="update_text"></block>
        <sep gap="32"></sep>
        <label text="For Reticle"></label>
        <block type="incrementtext"></block>
        <block type="decrementtext"></block>
        <sep gap="32"></sep>
        <label text="For Physics collision"></label>
        <block type="score"></block>
      </category>
      <category name="Variables" categorystyle="variable_category" custom="VARIABLE"></category>
      <category name="Functions" categorystyle="procedure_category">
        <block type="function"></block>
      </category>
      <category categorystyle="colour_category" name="Colour">
        <block type="colour_picker"></block>
        <block type="set_color"></block>
      </category>
      <category name="Actions" categorystyle="action_category">
        <block type="key_pressed"></block>
        <block type="mouse_events"></block>
        <sep gap="32"></sep>
        <label text="For Reticle"></label>
        <block type="OnClick"></block>
      </category>

      <category name="Environments" categorystyle="environment_category">
          <label text="Without physics environment"></label>
          <block type="environment_forest"></block>
          <block type="environment_egypt"></block>
          <block type="environment_goldmine"></block>
          <block type="environment_volcano"></block>
          <block type="environment_threetowers"></block>
          <block type="environment_japan"></block>
          <block type="environment_dream"></block>
          <block type="environment_checkerboard"></block>
          <sep gap="32"></sep>
          <label text="With physics environment"></label>
          <block type="physics_forest"></block>
          <block type="physics_egypt"></block>
          <block type="physics_city"></block>
          <block type="physics_volcano"></block>
        </category>


      <category name="Background" categorystyle="background_category">
        <block type="light"></block>
        <block type="my_new_block"></block>
      </category>
      
      <category name="Animation" categorystyle="Animation">
        <block type = "rotate_animation"></block>   
        <block type = "position_animation"></block>
        <block type = "scale_animation"></block>
        <block type = "playon"></block>
        <block type = "duration"></block>
        <block type = "loop"></block>
        <block type = "fromxyz"></block>
        <block type = "toxyz"></block>
      </category>

      <category name="Add Objects" categorystyle="basic_category">
        <block type="box"></block>
        <block type="sphere"></block>
        <block type="cylinder"></block>
        <block type="cone"></block>
        <block type="plane"></block>
        <block type="tetrahedron"></block>
        <block type="octahedron"></block>
        <block type="circle"></block>
        <block type = 'dodecahedron'></block>
        <block type = 'ring'></block>
        <block type = 'torus'></block>
        <block type = 'torusknot'></block>
        <block type = 'triangle'></block>
        <block type="import"></block>
      </category>


      <category name="Operations" categorystyle="operations_category">
        

        <block type="position"></block>
        <block type="position_x"></block>
        <block type="position_y"></block>
        <block type="position_z"></block>
        <block type="rotation"></block>
        <block type="rotation_x"></block>
        <block type="rotation_y"></block>
        <block type="rotation_z"></block>
        <block type="scaling"></block>
        <block type="scaling_x"></block>
        <block type="scaling_y"></block>
        <block type="scaling_z"></block>
        <block type="destroy_object"></block>
        

        

      
      </category>
      <category name="Sensing" categorystyle="sensing_category">
        <block type="get_position_x"></block>
        <block type="get_position_y"></block>
        <block type="get_position_z"></block>
        <block type="get_rotation_x"></block>
        <block type="get_rotation_y"></block>
        <block type="get_rotation_z"></block>
        <block type="get_scale_x"></block>
        <block type="get_scale_y"></block>
        <block type="get_scale_z"></block>
        <block type="get_color"></block>
      </category>
      <category name="Delay" categorystyle="delay_category">
        <block type="setinterval"></block>
        <block type="setintervalloop"></block>
        <block type="settimeout"></block>
      </category>
      
      <category name="Controls" categorystyle="control_category">
        <block type="movement"></block>
        <block type="wasdmovement"></block>
      </category>

      <category name="Physics" categorystyle="physics_category">
        <block type="static"></block>
        <block type="dynamic"></block>
        <block type="collision"></block>
        <block type="worldphysics"></block>
      </category>
      
      <category name="Characters" categorystyle="char_category">
        <block type="character1"></block>
        <block type="character2"></block>
        <block type="character3"></block>
        <block type="character4"></block>
        <block type="character5"></block>
      </category>

      <category name="Examples" categorystyle="example_category">
        <block type="cars"></block>
        <block type="planes"></block>
        <block type="shooter"></block>
        <block type="bowling"></block>
      </category>

      <category name="Sound" categorystyle="sound_category">
        <block type="sound"></block>
        <block type="volume"></block>
        <block type="play"></block>
        <block type="pause"></block>
        <block type="stop"></block>
      </category>

      <category name="IoT" categorystyle="iot_category">
        <label text="For Thingspeak"></label>
        <block type="iot_block"></block>
        <block type="iot_block_upload"></block>
        <block type="multiple_iot_block_upload"></block>
          <block type="fieldnumberandvalue"></block>
        <sep gap="32"></sep>
        <label text="For Custom server"></label>
        <block type="customserverget"></block>
        <block type="customserverpost"></block>
      </category>
  
          
  
        </xml>
        
      
      </section>
        <script crossorigin="anonymous">
          var demoWorkspace = Blockly.inject('blocklyDiv',
              {
               toolbox: document.getElementById('toolbox'),
               renderer: 'zelos',
               grid:
                  {
                    spacing: 20,
                    length: 3,
                    colour: '#ccc',
                    snap: true
                  },
                zoom:
                  {
                    controls: true,
                    wheel: true,
                    startScale: 1.0,
                    maxScale: 3,
                    minScale: 0.3,
                    scaleSpeed: 1.2,
                    pinch: true
                  },
                move:
                  {
                    scrollbars: true,
                    drag: true,
                    wheel: true
                  },
              trashcan: true});
      
              function download(filename) 
              {
                  var xml = Blockly.Xml.workspaceToDom(demoWorkspace);
                  var xml_text = Blockly.Xml.domToText(xml);
                  var pom = document.createElement('a');
                  pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(xml_text));
                  pom.setAttribute('download', filename);
                  
                  // pom.setAttribute('target','_blank');
                  if (document.createEvent) {
                      var event = document.createEvent('MouseEvents');
                      event.initEvent('click', true, true);
                      pom.dispatchEvent(event);
                  }
                  else {
                      pom.click();
                  }
              }
  
             
  
  
  
  
  
            function runcode() 
            {
              
              var code = Blockly.JavaScript.workspaceToCode(demoWorkspace);
              //alert(code);
              return code
            }
  
            
  
  
           
  
  
  
            function savecode()
            {
              var xml = Blockly.Xml.workspaceToDom(demoWorkspace);
              var xml_text = Blockly.Xml.domToText(xml);
              return xml_text
            }
  
  
          
            
        </script>
  
  
        <script type="text/javascript">
          function updateDiv()
          {
          
  
  
            fetch("blocks/blocks.xml")
            .then( response => response.text() )
            .then( text => Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(text), demoWorkspace)); 
  
          
        }
          
          
          </script> 
  
      
  
      <script>
        function loadblock() {
          if(count <= 2){
            try{
              var xml = Blockly.Xml.textToDom(localStorage.getItem('${uname}-${pname}-lmx'));
              Blockly.Xml.domToWorkspace(xml, demoWorkspace);        
              clearInterval(myintVar);
            }
            catch(err)
            {
              console.log(err);
            }
            count++;
          }
  
        }
  
      </script>
      <script>
        var myVar;
        var myintVar;
        var count =0;
        function myFunction() {
          myVar = setTimeout(showPage, 3000);
          myintVar =setInterval(blockloadinterval,1000);
        }
        
        function showPage() {
          document.getElementById("loader").style.display = "none";        
        }
  
        function blockloadinterval()
        {
          
            loadblock();
        }
        </script>
  
      <script src = "blockcode.js"></script>
  
  
      </body>
      </html>
  
  `;

  var iframeDesign = `
    <html>
      <head><meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">   
      
          <!-- Bootstrap CSS -->
          <!-- Bootstrap CSS -->
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    
         
        <script src="/assets/src/aframe.min.js"></script>
        <script src="/assets/src/aframe-environment-component.min.js"></script>
        
        <script src="/assets/src/aframe-text-geometry-component.min.js"></script>
        <script src="/assets/src/aframe-extras.min.js"></script>

        
        <script src="/assets/src/aframe-physics-extras.min.js"></script>
        <script src="/assets/src/aframe-physics-system.min.js"></script>

        <!-- <script src="/assets/src/a.js"></script>
        <script src="/assets/src/aframe-inspector.min.js"></script>
         <script src="/assets/src/a.js"></script>
        <script src="https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js"></script> 
        <script src="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js"></script>-->
        
  
      
        <script>
              
          function TextIncrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value++;
          }
          function TextDecrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value--;
          }
        </script>
  <style>
    /* Center the loader */
    #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    /* Add animation to "page content" */
    .animate-bottom {
      position: relative;
      -webkit-animation-name: animatebottom;
      -webkit-animation-duration: 1s;
      animation-name: animatebottom;
      animation-duration: 1s
    }
    
    @-webkit-keyframes animatebottom {
      from { bottom:-100px; opacity:0 } 
      to { bottom:0px; opacity:1 }
    }
    
    @keyframes animatebottom { 
      from{ bottom:-100px; opacity:0 } 
      to{ bottom:0; opacity:1 }
    }
    
    #myDiv {
      display: none;
      text-align: center;
    }
    </style>
        <style>
          body {
            background-color:#ffffff;
            font-family: sans-serif;
          }
          h1 {
            font-weight: normal;
            font-size: 140%;
          }
          a-scene {
            height: 100vh;
            width: 100%;
          }
          
        </style>
      </head>
  <body onload="myFunction()">
        
  <div id="loader"></div>
  
  
  <section id="aframe-section">
        <a-scene id="Default-scene" light="defaultLightsEnabled: false" physics="debug: false;">
  
          <a-assets id="asset">
  
          </a-assets>
        
          <a-entity id="Main Parent"  >
            <a-entity id="Light 1" light="type: ambient; color: #BBB;"></a-entity>
          <a-entity id="Light 2" light="type: directional; color: #BBB; intensity: 0.4" position="-0.5 1 1"></a-entity>
            <a-entity id="rig" movement-controls="enabled:false" position="0 2.2 4">
            <a-entity id="Camera-entities" camera look-controls>
                        <a-entity id="Camera-controls" animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
                          animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
                          animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
                          cursor="fuse: true;"
                          material="color: cyan; shader: flat"
                          position="0 0 -3"
                          geometry="primitive: ring ; radiusInner: 0.10; radiusOuter: 0.15;"></a-entity>
              </a-entity>
            </a-entity>
              
        <a-sky id="Default-sky" position="0 0 1.50303" rotation="-0.3202834074781302 -2.4499675319794 -1.3584829322551817" color="gray"></a-sky>
      
        </a-entity>
        </a-scene>
  
  </section>
  
  
      <script>
  
        function runcode(code) 
            {
              try
              { 
                eval(code);
              } 
              catch(e) 
              {
                console.log(code);
                alert(e);
              }
            }
  
          function close_fcking_inspector()
          {
              setTimeout(function(){ if(window.parent.document.getElementById('intermediate_iframe_block').style.display == "none")
                  { var my_awesome_script = document.createElement('script');
                   // my_awesome_script.setAttribute('src',"assets/src/aframe-inspector.min.js");
                    my_awesome_script.setAttribute('src',"https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js");
  
                    
                    document.head.appendChild(my_awesome_script); }},2000);
          }
  
          function loadInspector()
          {
            var my_awesome_script = document.createElement('script');
            my_awesome_script.setAttribute('src',"/assets/src/a.js");
            //my_awesome_script.setAttribute('src',"https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js");          
            document.head.appendChild(my_awesome_script); 
          }
  
          function loadInspectorContent()
          {

            document.getElementById('Main Parent').remove();

            var parent = document.getElementById('Default-scene');                    
            var aentity = document.createElement('a-entity');
            aentity.setAttribute('id','Main Parent');
            parent.appendChild(aentity);

            document.getElementById('Main Parent').innerHTML = localStorage.getItem('${uname}-${pname}-lmth').split("/////")[0];

            try{
              if(localStorage.getItem('${uname}-${pname}-lmth').split("/////")[1].length > 1)
              {
               console.log("length "+localStorage.getItem('${uname}-${pname}-lmth').split("/////")[1].length);
               var evv = document.createElement('a-entity');
               evv.setAttribute('id','environment');
               evv.setAttribute('environment', 'preset:' + localStorage.getItem('${uname}-${pname}-lmth').split("/////")[1] );
               parent.appendChild(evv);
              }
           }
            catch{
              console.log('no env');
             
            }

            
            var my_awesome_script = document.createElement('script');
            my_awesome_script.setAttribute('src',"/assets/src/a.js");
            document.head.appendChild(my_awesome_script); 


            
            AFRAME.INSPECTOR.initUI();
          }
  
      </script>
      <script>
        var myVar;
        
        function myFunction() {
          // loadInspector();
          //document.getElementById("aframe-section").innerHTML = localStorage.getItem('src');
          myVar = setTimeout(showPage, 3000);
        }
        
        function showPage() {
          //loadInspector();
          document.getElementById("loader").style.display = "none";
          if(localStorage.getItem('${uname}-${pname}-lmth'))
          {
            //console.log("here in content" + localStorage.getItem('${uname}-${pname}-lmth'));
            loadInspectorContent();
          }
          else
          {
            console.log("here in no content");
            loadInspector();
          }
        }
        </script>
  
  
  
      </body>
      </html>
  
  `;

  var aframeOut = `
    <html>
      <head><meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      
          <!-- Bootstrap CSS -->
          <!-- Bootstrap CSS -->
         
        <script src="/assets/src/aframe.min.js"></script>
        <script src="/assets/src/aframe-environment-component.min.js"></script>
        
        <script src="/assets/src/aframe-text-geometry-component.min.js"></script>
        <script src="/assets/src/aframe-extras.min.js"></script>
        
        <!--<script src="/assets/src/aframe-inspector.min.js"></script> -->
  
        <!-- <script src="https://unpkg.com/aframe-touch-inspector@1.1.2/dist/aframe-inspector.min.js"></script> -->
  
        <script src="/assets/src/aframe-physics-extras.min.js"></script>
        <script src="/assets/src/aframe-physics-system.min.js"></script>
      
        <script>
              
          function TextIncrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value++;
          }
          function TextDecrementFunction(t){
            var x = document.getElementById(t).attributes.value;
            x.value--;
          }
        </script>
  <style>
    /* Center the loader */
    #loader {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
    
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    /* Add animation to "page content" */
    .animate-bottom {
      position: relative;
      -webkit-animation-name: animatebottom;
      -webkit-animation-duration: 1s;
      animation-name: animatebottom;
      animation-duration: 1s
    }
    
    @-webkit-keyframes animatebottom {
      from { bottom:-100px; opacity:0 } 
      to { bottom:0px; opacity:1 }
    }
    
    @keyframes animatebottom { 
      from{ bottom:-100px; opacity:0 } 
      to{ bottom:0; opacity:1 }
    }
    
    #myDiv {
      display: none;
      text-align: center;
    }
    </style>
        <style>
          body {
            background-color:#ffffff;
            font-family: sans-serif;
          }
          h1 {
            font-weight: normal;
            font-size: 140%;
          }
          a-scene {
            height: 100vh;
            width: 100%;
          }
          
        </style>
      </head>
  <body onload="myFunction()">
        
  <div id="loader"></div>
  
  
  <section id="aframe-section">
        <a-scene id="Default-scene" light="defaultLightsEnabled: false" physics="debug: false;">
        
          
          <a-entity id="Main Parent"  >
              <a-entity id="Light 1" light="type: ambient; color: #BBB;"></a-entity>
              <a-entity id="Light 2" light="type: directional; color: #BBB; intensity: 0.4" position="-0.5 1 1"></a-entity>
              <a-entity id="rig" movement-controls="enabled:false" position="0 2.2 4">
            <a-entity id="Camera-entities" camera look-controls>
                        <a-entity id="Camera-controls" animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
                          animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
                          animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
                          cursor="fuse: true;"
                          material="color: cyan; shader: flat"
                          position="0 0 -3"
                          geometry="primitive: ring ; radiusInner: 0.10; radiusOuter: 0.15;"></a-entity>
              </a-entity>
            </a-entity>
                
          <a-sky id="Default-sky" position="0 0 1.50303" rotation="-0.3202834074781302 -2.4499675319794 -1.3584829322551817" color="gray"></a-sky>
        
          </a-entity>
        </a-scene>
  
  </section>


  
  
      <script>
  
        function runcode(code) 
            {
              try
              { 
                eval("var score = 0;"+code);
              } 
              catch(e) 
              {
                console.log(code);
                alert(e);
              }
            }
        
        function myFunction() {
          myVar = setTimeout(showPage, 3000);
        }
        
        function showPage() {
          document.getElementById("loader").style.display = "none";
        }
        </script>
  
  
    </body>
  </html>
  
  `;

  const toggleToCode = () => {
    document
      .getElementById("intermediate_iframe_aframe")
      .contentDocument.getElementById("Main Parent")
      .flushToDOM(true);
    var x = document
      .getElementById("intermediate_iframe_aframe")
      .contentDocument.getElementById("Main Parent");
    document
      .getElementById("intermediate_iframe_aframe_out")
      .contentDocument.getElementById("Main Parent").innerHTML = x.innerHTML;
    let env_available = false;
    try {
      var x = document
        .getElementById("intermediate_iframe_aframe")
        .contentDocument.getElementById("environment");
      var preset = x.getAttribute("environment").preset;
      env_available = true;
    } catch {
      env_available = false;
    }
    try {
      if (env_available) {
        var x = document
          .getElementById("intermediate_iframe_aframe")
          .contentDocument.getElementById("environment");
        var preset = x.getAttribute("environment").preset;
        var env = document
          .getElementById("intermediate_iframe_aframe_out")
          .contentDocument.getElementById("environment");
        env.remove();
        var parent = document
          .getElementById("intermediate_iframe_aframe_out")
          .contentDocument.getElementById("Default-scene");
        var aentity = document
          .getElementById("intermediate_iframe_aframe_out")
          .contentDocument.createElement("a-entity");
        aentity.setAttribute("id", "environment");
        aentity.setAttribute("environment", `preset: ${preset}`);
        parent.appendChild(aentity);
      }
    } catch {
      var parent = document
        .getElementById("intermediate_iframe_aframe_out")
        .contentDocument.getElementById("Default-scene");
      var aentity = document
        .getElementById("intermediate_iframe_aframe_out")
        .contentDocument.createElement("a-entity");
      aentity.setAttribute("id", "environment");
      aentity.setAttribute("environment", `preset: ${preset}`);
      parent.appendChild(aentity);
    }
  };

  const runIntermedaiteCode = () => {
    document
      .getElementById("intermediate_iframe_aframe_out")
      .contentWindow.location.reload(true);
    setTimeout(function () {
      toggleToCode();
      var iframe = document.getElementById("intermediate_iframe_block");
      var code = iframe.contentWindow.runcode();
      document
        .getElementById("intermediate_iframe_aframe_out")
        .contentWindow.runcode(code);
    }, 2000);
  };

  const setDesignView = () => {
    document.getElementById("previewgif").style.transition =
      "all 0.1s ease-in-out";
    document.getElementById("previewgif").style.filter = "opacity(1)";
    document.getElementById("previewgif").style.zIndex = "99";
    document.getElementById("previewgif").style.transition =
      "all 0.25s ease-in-out";
    setCurrentView("design");
    setTimeout(() => {
      document.getElementById("intermediate_iframe_aframe").style.display =
        "flex";
      document.getElementById("intermediate_iframe_block").style.display =
        "none";
      document.getElementById("intermediate_iframe_block").style.left = "0px";
      document.getElementById("intermediate_iframe_aframe_out").style.display =
        "none";
      document.getElementById("intermediate_iframe_aframe_out").style.left =
        "52.1vw";
      try {
        document.getElementById(
          "intermediatenew-moveright-icon"
        ).style.display = "none";
      } catch {}
      try {
        document.getElementById("intermediatenew-move-icon").style.display =
          "none";
      } catch {}
    }, 250);
    setTimeout(() => {
      document.getElementById("previewgif").style.filter = "opacity(0)";
      document.getElementById("previewgif").style.zIndex = "-5";
    }, 1000);
  };

  const setCodingView = () => {
    document.getElementById("previewgif").style.transition =
      "all 0.1s ease-in-out";
    document.getElementById("previewgif").style.filter = "opacity(1)";
    document.getElementById("previewgif").style.zIndex = "99";
    document.getElementById("previewgif").style.transition =
      "all 0.25s ease-in-out";
    setCurrentView("code");
    setTimeout(() => {
      document.getElementById("intermediate_iframe_aframe").style.display =
        "none";
      document.getElementById("intermediate_iframe_block").style.display =
        "flex";
      document.getElementById("intermediate_iframe_block").style.left = "0px";
      document.getElementById("intermediate_iframe_aframe_out").style.display =
        "flex";
      document.getElementById("intermediate_iframe_aframe_out").style.left =
        "52.1vw";
      try {
        document.getElementById(
          "intermediatenew-moveright-icon"
        ).style.display = "flex";
      } catch {}
      try {
        document.getElementById("intermediatenew-move-icon").style.display =
          "flex";
      } catch {}
      toggleToCode();
    }, 250);
    setTimeout(() => {
      document.getElementById("previewgif").style.filter = "opacity(0)";
      document.getElementById("previewgif").style.zIndex = "-5";
    }, 1000);
  };

  const publishCode = () => {
    // if(codeSaved)
    // {
    //   setQRmodalShow(true);
    //   document.getElementById("qrmodal").style.display = "block";
    // }
    // else
    // {
    //   alert("Please save your code");
    // }
    if (document.getElementById("project-name").value.trim() === "") {
      alert("please enter project name");
    } else if (localStorage.getItem(Auth.user.username) === "free") {
      alert(
        "This is a free user account. Please purchase a premium coupon code to save and publish your projects."
      );
    } else {
      saveCode();
      setQRmodalShow(true);
      document.getElementById("qrmodal").style.display = "block";
    }
  };
  const saveCode = () => {
    if (localStorage.getItem(Auth.user.username) === "free") {
      alert(
        "This is a free user account. Please purchase a premium coupon code to save and publish your projects."
      );
    } else {
      setCurrentStatus("saving");
      setTimeout(() => {
        setCurrentStatus("saved");
      }, 4000);
      setTimeout(() => {
        setCurrentStatus("");
      }, 8000);
      let sc = SaveCodeIntermediate({
        username: Auth.user.username,
        projectname: document.getElementById("project-name").value.trim(),
      });
      setCodeSaved(true);
    }
  };

  const handleMove = () => {
    if (moveSide === "left") {
      document.getElementById("intermediate_iframe_aframe_out").style.display =
        "none";
      document.getElementById("intermediate_iframe_block").style.width =
        "99.9vw";
      setMoveSide("right");
    } else if (moveSide === "right") {
      document.getElementById("intermediate_iframe_aframe_out").style.display =
        "flex";
      document.getElementById("intermediate_iframe_block").style.width =
        "52.1vw";
      setMoveSide("left");
    }
  };

  return (
    <>
      <div id="errorwindow">
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Project does not exist! Please check link and try again
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/");
            }}
          >
            Go Home
          </Button>
        </div>
      </div>
      <div id="qrmodal">
        <div
          style={{ display: "flex", height: "8vh", justifyContent: "center" }}
        >
          <span
            style={{
              display: "flex",
              width: "90%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Scan the QR Code or click on the button to open your in new tab
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={handleQRmodalClose}
          >
            <h4>X</h4>
          </span>
        </div>

        <hr />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5vh",
          }}
        >
          {/* <QRCode value={encodeURI(`https://api.enablar.co/dev/play?token=${uname}-${codeSaved?document.getElementById("project-name").value.trim():pname}`)} size={172} /> */}
          <QRCode
            value={encodeURI(`https://m.enablar.co/1?p=${projectid}`)}
            size={172}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <a target="_blank" href={encodeURI(`https://api.enablar.co/dev/play?token=${uname}-${codeSaved?document.getElementById("project-name").value.trim():pname}`)} > */}
          <a
            target="_blank"
            href={encodeURI(`https://m.enablar.co/1?p=${projectid}`)}
          >
            <Button variant="contained" color="primary">
              Open in new tab
            </Button>
          </a>
          &nbsp;
          {/* <Button variant='contained' color="primary" onClick={() => { setLinkCopied(true);setTimeout(() => {setLinkCopied(false)},4000) ; navigator.clipboard.writeText(encodeURI(`https://api.enablar.co/dev/play?token=${uname}-${codeSaved?document.getElementById("project-name").value.trim():pname}`)) }}>
            Copy link
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setLinkCopied(true);
              setTimeout(() => {
                setLinkCopied(false);
              }, 4000);
              navigator.clipboard.writeText(
                encodeURI(`https://m.enablar.co/1?p=${projectid}`)
              );
            }}
          >
            Copy link
          </Button>
          {linkCopied ? (
            <Alert
              onClose={() => {
                setLinkCopied(false);
              }}
            >
              Link copied
            </Alert>
          ) : (
            <></>
          )}
        </div>
        {/* <div style={{ marginLeft: "18vw", paddingTop: "2vh" }}>
                    <Button variant="primary" onClick={handleQRmodalClose}>
                        Close
                    </Button>
                </div> */}
      </div>

      <img id="previewgif" src="/assets/images/preview.gif"></img>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {currentView === "design" ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push("/");
                  }}
                  style={{
                    cursor: "pointer",
                    marginTop: "-12px",
                    backgroundColor: "#00A86B",
                    color: "white",
                  }}
                >
                  Home
                </Button>
                <span style={{ position: "relative", top: "-7px" }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                <input
                  placeholder="Enter project name"
                  type="text"
                  id="project-name"
                  style={{ position: "relative", top: "-6px" }}
                />
                <span style={{ position: "relative", top: "-7px" }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                <Button
                  color="inherit"
                  style={{
                    position: "relative",
                    top: "-4px",
                    backgroundColor: "#00A86B",
                    color: "white",
                  }}
                  disabled={status === "saving" ? true : false}
                  onClick={saveCode}
                >
                  Save
                </Button>
                {status === "saving" ? (
                  <span
                    style={{
                      position: "relative",
                      top: "-10px",
                      opacity: "0.5",
                      marginLeft: "6px",
                    }}
                  >
                    {" "}
                    <CircularProgress
                      style={{
                        color: "white",
                        position: "relative",
                        top: "5px",
                        opacity: "0.5",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    &nbsp;saving..
                  </span>
                ) : (
                  <></>
                )}
                {status === "saved" ? (
                  <span
                    style={{
                      position: "relative",
                      top: "-6px",
                      opacity: "0.5",
                      marginLeft: "6px",
                    }}
                  >
                    saved
                  </span>
                ) : (
                  <></>
                )}
                <div
                  style={{ position: "absolute", right: "2vw", top: "10px" }}
                >
                  <Button
                    style={{ backgroundColor: "#FF8C1A", color: "white" }}
                    onClick={setCodingView}
                  >
                    Code
                  </Button>
                </div>
                <DesignToolBar type="intermediate" />
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push("/");
                  }}
                  style={{
                    cursor: "pointer",
                    marginTop: "-12px",
                    backgroundColor: "#00A86B",
                    color: "white",
                  }}
                >
                  Home
                </Button>
                <span style={{ position: "relative", top: "-7px" }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                <input
                  type="text"
                  id="project-name"
                  style={{ position: "relative", top: "-6px" }}
                />
                <span style={{ position: "relative", top: "-7px" }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
                <Button
                  color="inherit"
                  style={{
                    position: "relative",
                    top: "-4px",
                    backgroundColor: "#00A86B",
                    color: "white",
                  }}
                  disabled={status === "saving" ? true : false}
                  onClick={saveCode}
                >
                  Save
                </Button>
                {status === "saving" ? (
                  <span
                    style={{
                      position: "relative",
                      top: "-10px",
                      opacity: "0.5",
                      marginLeft: "6px",
                    }}
                  >
                    {" "}
                    <CircularProgress
                      style={{
                        color: "white",
                        position: "relative",
                        top: "5px",
                        opacity: "0.5",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    &nbsp;saving..
                  </span>
                ) : (
                  <></>
                )}
                {status === "saved" ? (
                  <span
                    style={{
                      position: "relative",
                      top: "-6px",
                      opacity: "0.5",
                      marginLeft: "6px",
                    }}
                  >
                    saved
                  </span>
                ) : (
                  <></>
                )}
                <div
                  style={{ position: "absolute", right: "2vw", top: "10px" }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#FF8C1A", color: "white" }}
                    onClick={setDesignView}
                  >
                    Design
                  </Button>
                  <span style={{ position: "relative", top: "-2px" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#FF8C1A", color: "white" }}
                    onClick={runIntermedaiteCode}
                  >
                    Run
                  </Button>
                  <span style={{ position: "relative", top: "-2px" }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#FF8C1A", color: "white" }}
                    onClick={publishCode}
                  >
                    Publish
                  </Button>
                </div>
              </>
            )}
          </Toolbar>
        </AppBar>
      </div>

      {moveSide === "left" ? (
        <Button
          variant="contained"
          id="intermediatenew-move-icon"
          color="primary"
          onClick={handleMove}
        >
          <ChevronRightIcon />{" "}
        </Button>
      ) : (
        <Button
          variant="contained"
          id="intermediatenew-moveright-icon"
          color="primary"
          onClick={handleMove}
        >
          <ChevronLeftIcon />{" "}
        </Button>
      )}

      <section>
        <iframe
          className="border-white"
          id="intermediate_iframe_aframe"
          srcDoc={iframeDesign}
        >
          {" "}
        </iframe>
        <iframe
          className="border-white"
          id="intermediate_iframe_block"
          srcDoc={blockData}
        >
          {" "}
        </iframe>
        <iframe
          className="border-white"
          id="intermediate_iframe_aframe_out"
          srcDoc={aframeOut}
        ></iframe>
      </section>
    </>
  );
};

export default IntermediateLoad;
